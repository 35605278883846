// project import
import dashboard from './staffdashboard';

// ==============================|| MENU ITEMS ||============================== //

const staffItems = {
    items: [dashboard]
};

export default staffItems;
