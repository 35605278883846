// project import
import pages from './pages';
import dashboard from './admindashboard';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, pages]
};

export default menuItems;
