// material-ui
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

// project import
import NavGroup from './NavGroup';
import menuItems from '../../../../../menu-items/menuItems';
import staffItems from '../../../../../menu-items/staffItems';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
    const User = useSelector(state => state.User)
    const { user } = User

    const navGroups = user?.is_admin ? menuItems.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Fix - Navigation Group
                    </Typography>
                );
        }
    })
    :
    staffItems.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Fix - Navigation Group
                    </Typography>
                );
        }
    })
    ;

    return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
