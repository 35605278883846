export const REGISTER_COMPANY_SUCCESS = 'REGISTER_COMPANY_SUCCESS';
export const REGISTER_COMPANY_REQUEST = 'REGISTER_COMPANY_REQUEST';
export const REGISTER_COMPANY_FAIL = 'REGISTER_COMPANY_FAIL';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';

export const ALL_USERS_REQUEST = 'ALL_USERS_REQUEST';
export const ALL_USERS_SUCCESS = 'ALL_USERS_SUCCESS';
export const ALL_USERS_FAIL = 'ALL_USERS_FAIL';

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_FAIL = 'GET_USER_FAIL';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const INACTIVE_USER_REQUEST = 'INACTIVE_USER_REQUEST';
export const INACTIVE_USER_SUCCESS = 'INACTIVE_USER_SUCCESS';
export const INACTIVE_USER_FAIL = 'INACTIVE_USER_FAIL';

export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL';

export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS';
export const USER_LOADED_FAIL = 'USER_LOADED_FAIL';

export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';

export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';
export const PASSWORD_RESET_CONFIRM_SUCCESS = 'PASSWORD_RESET_CONFIRM_SUCCESS';
export const PASSWORD_RESET_CONFIRM_FAIL = 'PASSWORD_RESET_CONFIRM_FAIL';

export const SET_PASSWORD_REQUEST = 'SET_PASSWORD_REQUEST';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_FAIL = 'SET_PASSWORD_FAIL';

export const LOGOUT = 'LOGOUT';

export const GET_COMPANYS_SUCCESS = 'GET_COMPANYS_SUCCESS';
export const GET_COMPANYS_REQUEST = 'GET_COMPANYS_REQUEST';
export const GET_COMPANYS_FAIL = 'GET_COMPANYS_FAIL';

export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_REQUEST = 'GET_COMPANY_REQUEST';
export const GET_COMPANY_FAIL = 'GET_COMPANY_FAIL';

export const GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS';
export const GET_BRANDS_REQUEST = 'GET_BRANDS_REQUEST';
export const GET_BRANDS_FAIL = 'GET_BRANDS_FAIL';

export const GET_BRAND_SUCCESS = 'GET_BRAND_SUCCESS';
export const GET_BRAND_REQUEST = 'GET_BRAND_REQUEST';
export const GET_BRAND_FAIL = 'GET_BRAND_FAIL';

export const GET_ALL_PACKAGE_PROFIT_SUCCESS = 'GET_ALL_PACKAGE_PROFIT_SUCCESS';
export const GET_ALL_PACKAGE_PROFIT_REQUEST = 'GET_ALL_PACKAGE_PROFIT_REQUEST';
export const GET_ALL_PACKAGE_PROFIT_FAIL = 'GET_ALL_PACKAGE_PROFIT_FAIL';

export const GET_PACKAGE_PROFIT_SUCCESS = 'GET_PACKAGE_PROFIT_SUCCESS';
export const GET_PACKAGE_PROFIT_REQUEST = 'GET_PACKAGE_PROFIT_REQUEST';
export const GET_PACKAGE_PROFIT_FAIL = 'GET_PACKAGE_PROFIT_FAIL';

export const CREATE_PACKAGE_PROFIT_SUCCESS = 'CREATE_PACKAGE_PROFIT_SUCCESS';
export const CREATE_PACKAGE_PROFIT_REQUEST = 'CREATE_PACKAGE_PROFIT_REQUEST';
export const CREATE_PACKAGE_PROFIT_FAIL = 'CREATE_PACKAGE_PROFIT_FAIL';

export const UPDATE_PACKAGE_PROFIT_SUCCESS = 'UPDATE_PACKAGE_PROFIT_SUCCESS';
export const UPDATE_PACKAGE_PROFIT_REQUEST = 'UPDATE_PACKAGE_PROFIT_REQUEST';
export const UPDATE_PACKAGE_PROFIT_FAIL = 'UPDATE_PACKAGE_PROFIT_FAIL';

export const DELETE_PACKAGE_PROFIT_SUCCESS = 'DELETE_PACKAGE_PROFIT_SUCCESS';
export const DELETE_PACKAGE_PROFIT_REQUEST = 'DELETE_PACKAGE_PROFIT_REQUEST';
export const DELETE_PACKAGE_PROFIT_FAIL = 'DELETE_PACKAGE_PROFIT_FAIL';

export const CREATE_PACKAGE_CATEGORY_SUCCESS = 'CREATE_PACKAGE_CATEGORY_SUCCESS';
export const CREATE_PACKAGE_CATEGORY_REQUEST = 'CREATE_PACKAGE_CATEGORY_REQUEST';
export const CREATE_PACKAGE_CATEGORY_FAIL = 'CREATE_PACKAGE_CATEGORY_FAIL';
export const CREATE_PACKAGE_CATEGORY_RESET = 'CREATE_PACKAGE_CATEGORY_RESET';

export const GET_PACKAGES_SUCCESS = 'GET_PACKAGES_SUCCESS';
export const GET_PACKAGES_REQUEST = 'GET_PACKAGES_REQUEST';
export const GET_PACKAGES_FAIL = 'GET_PACKAGES_FAIL';

export const GET_PACKAGE_SUCCESS = 'GET_PACKAGE_SUCCESS';
export const GET_PACKAGE_REQUEST = 'GET_PACKAGE_REQUEST';
export const GET_PACKAGE_FAIL = 'GET_PACKAGE_FAIL';

export const DUPLICATE_PACKAGE_SUCCESS = 'DUPLICATE_PACKAGE_SUCCESS';
export const DUPLICATE_PACKAGE_REQUEST = 'DUPLICATE_PACKAGE_REQUEST';
export const DUPLICATE_PACKAGE_FAIL = 'DUPLICATE_PACKAGE_FAIL';

export const CREATE_PACKAGE_SUCCESS = 'CREATE_PACKAGE_SUCCESS';
export const CREATE_PACKAGE_REQUEST = 'CREATE_PACKAGE_REQUEST';
export const CREATE_PACKAGE_FAIL = 'CREATE_PACKAGE_FAIL';
export const CREATE_PACKAGE_RESET = 'CREATE_PACKAGE_RESET';

export const UPDATE_PACKAGE_SUCCESS = 'UPDATE_PACKAGE_SUCCESS';
export const UPDATE_PACKAGE_REQUEST = 'UPDATE_PACKAGE_REQUEST';
export const UPDATE_PACKAGE_FAIL = 'UPDATE_PACKAGE_FAIL';
export const UPDATE_PACKAGE_RESET = 'UPDATE_PACKAGE_RESET';

export const DELETE_PACKAGE_SUCCESS = 'DELETE_PACKAGE_SUCCESS';
export const DELETE_PACKAGE_REQUEST = 'DELETE_PACKAGE_REQUEST';
export const DELETE_PACKAGE_FAIL = 'DELETE_PACKAGE_FAIL';

export const GET_PACKAGE_VARIATIONS_SUCCESS = 'GET_PACKAGE_VARIATIONS_SUCCESS';
export const GET_PACKAGE_VARIATIONS_REQUEST = 'GET_PACKAGE_VARIATIONS_REQUEST';
export const GET_PACKAGE_VARIATIONS_FAIL = 'GET_PACKAGE_VARIATIONS_FAIL';

export const GET_PACKAGE_VARIATION_SUCCESS = 'GET_PACKAGE_VARIATION_SUCCESS';
export const GET_PACKAGE_VARIATION_REQUEST = 'GET_PACKAGE_VARIATION_REQUEST';
export const GET_PACKAGE_VARIATION_FAIL = 'GET_PACKAGE_VARIATION_FAIL';

export const DUPLICATE_PACKAGE_VARIATION_SUCCESS = 'DUPLICATE_PACKAGE_VARIATION_SUCCESS';
export const DUPLICATE_PACKAGE_VARIATION_REQUEST = 'DUPLICATE_PACKAGE_VARIATION_REQUEST';
export const DUPLICATE_PACKAGE_VARIATION_FAIL = 'DUPLICATE_PACKAGE_VARIATION_FAIL';

export const CREATE_PACKAGE_VARIATION_SUCCESS = 'CREATE_PACKAGE_VARIATION_SUCCESS';
export const CREATE_PACKAGE_VARIATION_REQUEST = 'CREATE_PACKAGE_VARIATION_REQUEST';
export const CREATE_PACKAGE_VARIATION_FAIL = 'CREATE_PACKAGE_VARIATION_FAIL';
export const CREATE_PACKAGE_VARIATION_RESET = 'CREATE_PACKAGE_VARIATION_RESET';

export const UPDATE_PACKAGE_VARIATION_SUCCESS = 'UPDATE_PACKAGE_VARIATION_SUCCESS';
export const UPDATE_PACKAGE_VARIATION_REQUEST = 'UPDATE_PACKAGE_VARIATION_REQUEST';
export const UPDATE_PACKAGE_VARIATION_FAIL = 'UPDATE_PACKAGE_VARIATION_FAIL';
export const UPDATE_PACKAGE_VARIATION_RESET = 'UPDATE_PACKAGE_VARIATION_RESET';

export const DELETE_PACKAGE_VARIATION_SUCCESS = 'DELETE_PACKAGE_VARIATION_SUCCESS';
export const DELETE_PACKAGE_VARIATION_REQUEST = 'DELETE_PACKAGE_VARIATION_REQUEST';
export const DELETE_PACKAGE_VARIATION_FAIL = 'DELETE_PACKAGE_VARIATION_FAIL';

export const CREATE_UNIT_SUCCESS = 'CREATE_UNIT_SUCCESS';
export const CREATE_UNIT_REQUEST = 'CREATE_UNIT_REQUEST';
export const CREATE_UNIT_FAIL = 'CREATE_UNIT_FAIL';
export const CREATE_UNIT_RESET = 'CREATE_UNIT_RESET';

export const GET_UNIT_SUCCESS = 'GET_UNIT_SUCCESS';
export const GET_UNIT_REQUEST = 'GET_UNIT_REQUEST';
export const GET_UNIT_FAIL = 'GET_UNIT_FAIL';

export const GET_PURCHASE_SUCCESS = 'GET_PURCHASE_SUCCESS';
export const GET_PURCHASE_REQUEST = 'GET_PURCHASE_REQUEST';
export const GET_PURCHASE_FAIL = 'GET_PURCHASE_FAIL';

export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST';
export const GET_CATEGORY_FAIL = 'GET_CATEGORY_FAIL';

export const GET_ALL_CATEGORY_SUCCESS = 'GET_ALL_CATEGORY_SUCCESS';
export const GET_ALL_CATEGORY_REQUEST = 'GET_ALL_CATEGORY_REQUEST';
export const GET_ALL_CATEGORY_FAIL = 'GET_ALL_CATEGORY_FAIL';

export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_FAIL = 'CREATE_CATEGORY_FAIL';
export const CREATE_CATEGORY_RESET = 'CREATE_CATEGORY_RESET';

export const GET_VENDORS_SUCCESS = 'GET_VENDORS_SUCCESS';
export const GET_VENDORS_REQUEST = 'GET_VENDORS_REQUEST';
export const GET_VENDORS_FAIL = 'GET_VENDORS_FAIL';

export const CREATE_VENDOR_SUCCESS = 'CREATE_VENDOR_SUCCESS';
export const CREATE_VENDOR_REQUEST = 'CREATE_VENDOR_REQUEST';
export const CREATE_VENDOR_FAIL = 'CREATE_VENDOR_FAIL';
export const CREATE_VENDOR_RESET = 'CREATE_VENDOR_RESET';

export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL';
export const UPDATE_CATEGORY_RESET = 'UPDATE_CATEGORY_RESET';

export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL';

export const CART_ADD_ITEM = 'CART_ADD_ITEM';
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM';

export const CART_SAVE_SHIPPING_ADDRESS = 'CART_SAVE_SHIPPING_ADDRESS,';
export const CART_DELETE_SHIPPING_ADDRESS = 'CART_DELETE_SHIPPING_ADDRESS,';

export const CART_CLEAR_ITEMS = 'CART_CLEAR_ITEMS';

export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL';

export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET';

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST';
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS';
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL';

export const ORDER_PAY_REQUEST = 'ORDER_PAY_REQUEST';
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS';
export const ORDER_PAY_FAIL = 'ORDER_PAY_FAIL';
export const ORDER_PAY_RESET = 'ORDER_PAY_RESET';

export const ORDER_DELIVER_REQUEST = 'ORDER_DELIVER_REQUEST';
export const ORDER_DELIVER_SUCCESS = 'ORDER_DELIVER_SUCCESS';
export const ORDER_DELIVER_FAIL = 'ORDER_DELIVER_FAIL';
export const ORDER_DELIVER_RESET = 'ORDER_DELIVER_RESET';

export const ORDER_LIST_MY_REQUEST = 'ORDER_LIST_MY_REQUEST';
export const ORDER_LIST_MY_SUCCESS = 'ORDER_LIST_MY_SUCCESS';
export const ORDER_LIST_MY_FAIL = 'ORDER_LIST_MY_FAIL';
export const ORDER_LIST_MY_RESET = 'ORDER_LIST_MY_RESET';

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST';
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS';
export const ORDER_LIST_FAIL = 'ORDER_LIST_FAIL';

export const ORDER_DELIVERED_REQUEST = 'ORDER_DELIVERED_REQUEST';
export const ORDER_DELIVERED_SUCCESS = 'ORDER_DELIVERED_SUCCESS';
export const ORDER_DELIVERED_FAIL = 'ORDER_DELIVERED_FAIL';
export const ORDER_DELIVERED_RESET = 'ORDER_DELIVERED_RESET';

export const GET_UPDATES_SUCCESS = 'GET_UPDATES_SUCCESS';
export const GET_UPDATES_REQUEST = 'GET_UPDATES_REQUEST';
export const GET_UPDATES_FAIL = 'GET_UPDATES_FAIL';

export const GET_PACK_COST_SUCCESS = 'GET_PACK_COST_SUCCESS';
export const GET_PACK_COST_REQUEST = 'GET_PACK_COST_REQUEST';
export const GET_PACK_COST_FAIL = 'GET_PACK_COST_FAIL';



export const QUOTATION_CREATE_REQUEST = 'QUOTATION_CREATE_REQUEST';
export const QUOTATION_CREATE_SUCCESS = 'QUOTATION_CREATE_SUCCESS';
export const QUOTATION_CREATE_FAIL = 'QUOTATION_CREATE_FAIL';

export const QUOTATION_ALL_REQUEST = 'QUOTATION_ALL_REQUEST';
export const QUOTATION_ALL_SUCCESS = 'QUOTATION_ALL_SUCCESS';
export const QUOTATION_ALL_FAIL = 'QUOTATION_ALL_FAIL';

export const QUOTATION_DETAIL_REQUEST = 'QUOTATION_DETAIL_REQUEST';
export const QUOTATION_DETAIL_SUCCESS = 'QUOTATION_DETAIL_SUCCESS';
export const QUOTATION_DETAIL_FAIL = 'QUOTATION_DETAIL_FAIL';

export const DUPLICATE_QUOTATION_SUCCESS = 'DUPLICATE_QUOTATION_SUCCESS';
export const DUPLICATE_QUOTATION_REQUEST = 'DUPLICATE_QUOTATION_REQUEST';
export const DUPLICATE_QUOTATION_FAIL = 'DUPLICATE_QUOTATION_FAIL';

export const UPDATE_QUOTATION_SUCCESS = 'UPDATE_QUOTATION_SUCCESS';
export const UPDATE_QUOTATION_REQUEST = 'UPDATE_QUOTATION_REQUEST';
export const UPDATE_QUOTATION_FAIL = 'UPDATE_QUOTATION_FAIL';

export const DELETE_QUOTATION_SUCCESS = 'DELETE_QUOTATION_SUCCESS';
export const DELETE_QUOTATION_REQUEST = 'DELETE_QUOTATION_REQUEST';
export const DELETE_QUOTATION_FAIL = 'DELETE_QUOTATION_FAIL';


export const CLIENT_ALL_REQUEST = 'CLIENT_ALL_REQUEST';
export const CLIENT_ALL_SUCCESS = 'CLIENT_ALL_SUCCESS';
export const CLIENT_ALL_FAIL = 'CLIENT_ALL_FAIL';

export const CLIENT_DETAIL_REQUEST = 'CLIENT_DETAIL_REQUEST';
export const CLIENT_DETAIL_SUCCESS = 'CLIENT_DETAIL_SUCCESS';
export const CLIENT_DETAIL_FAIL = 'CLIENT_DETAIL_FAIL';