// assets
import { DashboardOutlined } from '@ant-design/icons';
import { BsCart3, BsBasket2Fill,BsUiChecks } from "react-icons/bs";
import { TfiPackage } from "react-icons/tfi";
import { GiStockpiles } from "react-icons/gi";


// icons
const icons = {
    DashboardOutlined,
    BsBasket2Fill,
    BsCart3,
    BsUiChecks,
    TfiPackage,
    GiStockpiles
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
    id: 'group-dashboard',
    title: 'Navigation',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '',
            icon: icons.DashboardOutlined,
            breadcrumbs: false
        },
        {
            id: 'Product',
            title: 'Product',
            type: 'item',
            url: '/products',
            icon: icons.BsBasket2Fill
        },
        {
            id: 'Stock',
            title: 'Stock',
            type: 'item',
            url: '/allstocks',
            icon: icons.GiStockpiles,
            breadcrumbs: false
        },
        {
            id: 'Cart',
            title: 'Cart',
            type: 'item',
            url: '/cart',
            icon: icons.BsCart3,
            breadcrumbs: false
        },
        {
            id: 'All Orders',
            title: 'All Orders',
            type: 'item',
            url: '/allorders',
            icon: icons.BsUiChecks,
            breadcrumbs: false
        },
        {
            id: 'All Packages',
            title: 'All Packages',
            type: 'item',
            url: '/allpackages',
            icon: icons.TfiPackage,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
