// third-party
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';

// project import
import menu from './reducers/menu';

import {
    allcompaniesReducer,
    companyReducer,
    companyCreateReducer,
    allbrandsReducer,
    brandReducer,
    allpackageProfitsReducer,
    packageProfitReducer,
    createpackageProfitsReducer,
    updatepackageProfitsReducer,
    deletepackageProfitsReducer,
    packageCategoryCreateReducer,
    allpackagesReducer,
    packageReducer,
    duplicatePackageReducer,
    packageCreateReducer,
    packageUpdateReducer,
    packageDeleteReducer,
    packCostReducer,
    allpackageVariationsReducer,
    packageVariationReducer,
    duplicatePackageVariationReducer,
    packageVariationCreateReducer,
    packageVariationUpdateReducer,
    packageVariationDeleteReducer,
    unitCreateReducer,
    unitReducer,
    purchaseReducer,
    categoryReducer,
    allCategoryReducer,
    categoryCreateReducer,
    categoryUpdateReducer,
    categoryDeleteReducer,
    vendorReducer,
    vendorCreateReducer,
    User,
    userUpdateReducer,
    userInactiveReducer,
    userDeleteReducer,
    cartReducer,
    orderCreateReducer,
    orderDetailsReducer,
    orderPayReducer,
    orderDeliverReducer,
    orderListMyReducer,
    orderListReducer,
    updateReducer,
    singleUserReducer,

    quotationCreateReducer,
    allquotationsReducer,
    quotationReducer,
    duplicateQuotationReducer,
    quotationUpdateReducer,
    quotationDeleteReducer,

    allclientsReducer,
    clientReducer,
} from '../Reducers/Reducers';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const reducer = combineReducers({
    allCompanies: allcompaniesReducer,
    Company: companyReducer,
    createCompany: companyCreateReducer,
    allBrand: allbrandsReducer,
    User: User,
    Brand: brandReducer,
    Package: packageReducer,
    Category: categoryReducer,
    AllCategory: allCategoryReducer,
    createCategory: categoryCreateReducer,
    Vendor: vendorReducer,
    VendorCreate: vendorCreateReducer,
    AllPackageProfits: allpackageProfitsReducer,
    PackageProfitDetail: packageProfitReducer,
    CreatePackageProfit: createpackageProfitsReducer,
    UpdatePackageProfit: updatepackageProfitsReducer,
    DeletePackageProfit: deletepackageProfitsReducer,
    packageCategory: packageCategoryCreateReducer,
    allPackage: allpackagesReducer,
    createPackage: packageCreateReducer,
    PackageCost: packCostReducer,
    packageUpdate: packageUpdateReducer,
    packageDelete: packageDeleteReducer,
    packageVariations: allpackageVariationsReducer,
    packageVariation: packageVariationReducer,
    packageVariationDuplicate: duplicatePackageVariationReducer,
    packageVariationCreate: packageVariationCreateReducer,
    packageVariationUpdate: packageVariationUpdateReducer,
    packageVariationDelete: packageVariationDeleteReducer,
    duplicatePackage: duplicatePackageReducer,
    UnitCreate: unitCreateReducer,
    Units: unitReducer,
    Purchases: purchaseReducer,
    categoryUpdate: categoryUpdateReducer,
    categoryDelete: categoryDeleteReducer,

    cart: cartReducer,

    orderCreate: orderCreateReducer,
    orderDetails: orderDetailsReducer,
    orderPay: orderPayReducer,
    orderDeliver: orderDeliverReducer,
    orderListMy: orderListMyReducer,
    orderList: orderListReducer,
    Update: updateReducer,
    menu: menu,
    UpdateUser: userUpdateReducer,
    InactiveUser: userInactiveReducer,
    DeleteUser: userDeleteReducer,
    SingleUser: singleUserReducer,

    QuotationCreate: quotationCreateReducer,
    AllQuotation: allquotationsReducer,
    QuotationDetail: quotationReducer,
    quotationUpdate: quotationUpdateReducer,
    quotationDelete: quotationDeleteReducer,
    duplicateQuotation: duplicateQuotationReducer,

    AllClient: allclientsReducer,
    ClientDetail: clientReducer,
});

const store = configureStore({
    reducer: reducer,
    middleware: [thunk]
});

const { dispatch } = store;

export { store, dispatch };
