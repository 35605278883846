import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector, connect } from 'react-redux';
import { getCompany, getBrand } from '../../actions/actions';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, useMediaQuery } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import navigation from '../../menu-items/menuItems';
import Breadcrumbs from '../../Components/@extended/Breadcrumbs';

// types
import { openDrawer } from '../../Reducers/menu';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = ({ isAuthenticated, brand_id }) => {
    const theme = useTheme();
    const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
    const dispatch = useDispatch();

    const { drawerOpen } = useSelector((state) => state.menu);

    // drawer toggler
    const [open, setOpen] = useState(drawerOpen);
    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer({ drawerOpen: !open }));
    };

    // set media wise responsive drawer
    useEffect(() => {
        setOpen(!matchDownLG);
        dispatch(openDrawer({ drawerOpen: !matchDownLG }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownLG]);

    const navigate = useNavigate();

    const { cid, bid } = useParams();

    const Company = useSelector((state) => state.Company);
    const { loading: companyloading, error: companyerror, company: companyDetail } = Company;
    const Brand = useSelector((state) => state.Brand);
    const { error: errorbrand, brand } = Brand;

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
        if (cid !== undefined) {
            if (isAuthenticated !== null) {
                if (!isAuthenticated) {
                    navigate(`/${cid}/home/dashboard`)
                } else {
                    if (bid === undefined) {
                        if (brand_id !== undefined) {
                            navigate(`/dashboard/${cid}/${brand_id}`)
                        }
                    }
                }
            }
        } else {
            navigate(`/dashboard/home`)
        }
        if (!companyDetail?.name && cid !== undefined) {
            dispatch(getCompany(cid));  
        }
        if (!brand?.brand && bid !== undefined) {
            dispatch(getBrand(bid));  
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerOpen, navigate, isAuthenticated, brand, brand_id, bid, companyDetail]);

    if(companyerror){
        navigate(`/*`);
    }
    if(errorbrand){
        navigate(`/*`);
    }

    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <Header open={open} handleDrawerToggle={handleDrawerToggle} />
            <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
            <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
                <Toolbar />
                <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
                <Outlet />
            </Box>
        </Box>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.User.isAuthenticated,
    brand_id: state.User.user?.brand?._id
})

export default connect(mapStateToProps, {})(MainLayout);
