import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { getBrand } from '../../../../actions/actions';
import { Link, useParams } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open, brand_id }) => {
    const theme = useTheme();
    const { cid, bid } = useParams();
    const Brand = useSelector((state) => state.Brand);
    const { brand } = Brand;

    const dispatch = useDispatch();
    useEffect(() => {
        if (bid === undefined) {
            if (brand_id !== undefined) {
                dispatch(getBrand(brand_id))
            }
        } else{
            dispatch(getBrand(bid));
        }
    }, [dispatch, bid, brand_id]);

    return (
        // only available in paid version
        <DrawerHeaderStyled style={{ justifyContent: 'center', paddingLeft: '0' }} theme={theme} open={open}>
            <Stack direction="row" spacing={1} alignItems="center">
                <Link
                    style={{
                        overflow: 'hidden',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '130px'
                    }}
                    to={{ pathname: `/${cid}/home/dashboard` }}
                >
                    {brand && (
                        <img
                            style={{ width: '100%', objectFit: 'contain', justifyContent: 'center' }}
                            className="logo-img"
                            src={`${process.env.REACT_APP_API_URL_PROD}` + brand?.image}
                            alt={brand?.brand}
                        />
                    )}
                </Link>
            </Stack>
        </DrawerHeaderStyled>
    );
};

DrawerHeader.propTypes = {
    open: PropTypes.bool
};

const mapStateToProps = state => ({
    brand_id: state.User.user?.brand?._id
})

export default connect(mapStateToProps, { })(DrawerHeader);
