import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import HomeRoutes from './HomeRoutes';
import Page404 from '../pages/Page404';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([MainRoutes, HomeRoutes, LoginRoutes, 
        {
            path: '*',
            element: <Page404 />
        }
    ]);
}
