import {
    REGISTER_COMPANY_REQUEST,
    REGISTER_COMPANY_SUCCESS,
    REGISTER_COMPANY_FAIL,
    GET_COMPANYS_REQUEST,
    GET_COMPANYS_SUCCESS,
    GET_COMPANYS_FAIL,
    GET_COMPANY_REQUEST,
    GET_COMPANY_SUCCESS,
    GET_COMPANY_FAIL,
    GET_BRANDS_REQUEST,
    GET_BRANDS_SUCCESS,
    GET_BRANDS_FAIL,
    GET_BRAND_REQUEST,
    GET_BRAND_SUCCESS,
    GET_BRAND_FAIL,
    GET_UPDATES_REQUEST,
    GET_UPDATES_SUCCESS,
    GET_UPDATES_FAIL,
    GET_ALL_PACKAGE_PROFIT_REQUEST,
    GET_ALL_PACKAGE_PROFIT_SUCCESS,
    GET_ALL_PACKAGE_PROFIT_FAIL,
    GET_PACKAGE_PROFIT_REQUEST,
    GET_PACKAGE_PROFIT_SUCCESS,
    GET_PACKAGE_PROFIT_FAIL,
    CREATE_PACKAGE_PROFIT_REQUEST,
    CREATE_PACKAGE_PROFIT_SUCCESS,
    CREATE_PACKAGE_PROFIT_FAIL,
    UPDATE_PACKAGE_PROFIT_REQUEST,
    UPDATE_PACKAGE_PROFIT_SUCCESS,
    UPDATE_PACKAGE_PROFIT_FAIL,
    DELETE_PACKAGE_PROFIT_REQUEST,
    DELETE_PACKAGE_PROFIT_SUCCESS,
    DELETE_PACKAGE_PROFIT_FAIL,
    CREATE_PACKAGE_CATEGORY_REQUEST,
    CREATE_PACKAGE_CATEGORY_SUCCESS,
    CREATE_PACKAGE_CATEGORY_FAIL,
    CREATE_PACKAGE_CATEGORY_RESET,
    GET_PACKAGES_REQUEST,
    GET_PACKAGES_SUCCESS,
    GET_PACKAGES_FAIL,
    GET_PACKAGE_REQUEST,
    GET_PACKAGE_SUCCESS,
    GET_PACKAGE_FAIL,
    DUPLICATE_PACKAGE_REQUEST,
    DUPLICATE_PACKAGE_SUCCESS,
    DUPLICATE_PACKAGE_FAIL,
    UPDATE_PACKAGE_REQUEST,
    UPDATE_PACKAGE_SUCCESS,
    UPDATE_PACKAGE_FAIL,
    UPDATE_PACKAGE_RESET,
    DELETE_PACKAGE_REQUEST,
    DELETE_PACKAGE_SUCCESS,
    DELETE_PACKAGE_FAIL,
    GET_PACK_COST_REQUEST,
    GET_PACK_COST_SUCCESS,
    GET_PACK_COST_FAIL,
    CREATE_PACKAGE_REQUEST,
    CREATE_PACKAGE_SUCCESS,
    CREATE_PACKAGE_FAIL,
    CREATE_PACKAGE_RESET,
    GET_PACKAGE_VARIATIONS_REQUEST,
    GET_PACKAGE_VARIATIONS_SUCCESS,
    GET_PACKAGE_VARIATIONS_FAIL,
    GET_PACKAGE_VARIATION_REQUEST,
    GET_PACKAGE_VARIATION_SUCCESS,
    GET_PACKAGE_VARIATION_FAIL,
    DUPLICATE_PACKAGE_VARIATION_REQUEST,
    DUPLICATE_PACKAGE_VARIATION_SUCCESS,
    DUPLICATE_PACKAGE_VARIATION_FAIL,
    UPDATE_PACKAGE_VARIATION_REQUEST,
    UPDATE_PACKAGE_VARIATION_SUCCESS,
    UPDATE_PACKAGE_VARIATION_FAIL,
    UPDATE_PACKAGE_VARIATION_RESET,
    DELETE_PACKAGE_VARIATION_REQUEST,
    DELETE_PACKAGE_VARIATION_SUCCESS,
    DELETE_PACKAGE_VARIATION_FAIL,
    CREATE_PACKAGE_VARIATION_REQUEST,
    CREATE_PACKAGE_VARIATION_SUCCESS,
    CREATE_PACKAGE_VARIATION_FAIL,
    CREATE_PACKAGE_VARIATION_RESET,
    CREATE_UNIT_REQUEST,
    CREATE_UNIT_SUCCESS,
    CREATE_UNIT_FAIL,
    CREATE_UNIT_RESET,
    GET_UNIT_REQUEST,
    GET_UNIT_SUCCESS,
    GET_UNIT_FAIL,
    GET_PURCHASE_REQUEST,
    GET_PURCHASE_SUCCESS,
    GET_PURCHASE_FAIL,
    CREATE_CATEGORY_REQUEST,
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_FAIL,
    CREATE_CATEGORY_RESET,
    GET_VENDORS_REQUEST,
    GET_VENDORS_SUCCESS,
    GET_VENDORS_FAIL,
    CREATE_VENDOR_REQUEST,
    CREATE_VENDOR_SUCCESS,
    CREATE_VENDOR_FAIL,
    CREATE_VENDOR_RESET,
    GET_CATEGORY_REQUEST,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_FAIL,
    GET_ALL_CATEGORY_REQUEST,
    GET_ALL_CATEGORY_SUCCESS,
    GET_ALL_CATEGORY_FAIL,
    UPDATE_CATEGORY_REQUEST,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_FAIL,
    UPDATE_CATEGORY_RESET,
    DELETE_CATEGORY_REQUEST,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    ALL_USERS_SUCCESS,
    ALL_USERS_FAIL,
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USER_FAIL,
    ACTIVATION_SUCCESS,
    ACTIVATION_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    LOGOUT,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    SET_PASSWORD_SUCCESS,
    SET_PASSWORD_FAIL,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,
    INACTIVE_USER_REQUEST,
    INACTIVE_USER_SUCCESS,
    INACTIVE_USER_FAIL,
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_SAVE_SHIPPING_ADDRESS,
    CART_DELETE_SHIPPING_ADDRESS,
    CART_CLEAR_ITEMS,
    ORDER_CREATE_REQUEST,
    ORDER_CREATE_SUCCESS,
    ORDER_CREATE_FAIL,
    ORDER_CREATE_RESET,
    ORDER_DETAILS_REQUEST,
    ORDER_DETAILS_SUCCESS,
    ORDER_DETAILS_FAIL,
    ORDER_PAY_REQUEST,
    ORDER_PAY_SUCCESS,
    ORDER_PAY_FAIL,
    ORDER_PAY_RESET,
    ORDER_LIST_MY_REQUEST,
    ORDER_LIST_MY_SUCCESS,
    ORDER_LIST_MY_FAIL,
    ORDER_DELIVERED_REQUEST,
    ORDER_DELIVERED_SUCCESS,
    ORDER_DELIVERED_FAIL,
    ORDER_DELIVERED_RESET,
    ORDER_LIST_MY_RESET,
    ORDER_LIST_REQUEST,
    ORDER_LIST_SUCCESS,
    ORDER_LIST_FAIL,

    QUOTATION_CREATE_REQUEST,
    QUOTATION_CREATE_SUCCESS,
    QUOTATION_CREATE_FAIL,
    QUOTATION_ALL_REQUEST,
    QUOTATION_ALL_SUCCESS,
    QUOTATION_ALL_FAIL,
    QUOTATION_DETAIL_REQUEST,
    QUOTATION_DETAIL_SUCCESS,
    QUOTATION_DETAIL_FAIL,
    DUPLICATE_QUOTATION_REQUEST,
    DUPLICATE_QUOTATION_SUCCESS,
    DUPLICATE_QUOTATION_FAIL,
    UPDATE_QUOTATION_REQUEST,
    UPDATE_QUOTATION_SUCCESS,
    UPDATE_QUOTATION_FAIL,
    DELETE_QUOTATION_REQUEST,
    DELETE_QUOTATION_SUCCESS,
    DELETE_QUOTATION_FAIL,

    CLIENT_ALL_REQUEST,
    CLIENT_ALL_SUCCESS,
    CLIENT_ALL_FAIL,
    CLIENT_DETAIL_REQUEST,
    CLIENT_DETAIL_SUCCESS,
    CLIENT_DETAIL_FAIL,
} from '../Constants/Constants';

const initialState = {
    access: localStorage.getItem('access'),
    refresh: localStorage.getItem('refresh'),
    isAuthenticated: null,
    user: { brand: [] },
    users: [],
    signup: null,
    login: null
};

const initialStateCart = {
    cartItems: localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [],
    shippingAddress: localStorage.getItem('shippingAddress') ? JSON.parse(localStorage.getItem('shippingAddress')) : {}
};

export const User = (state = initialState, action) => {
    switch (action.type) {
        case AUTHENTICATED_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                signup: false,
                login: false
            };
        case LOGIN_SUCCESS:
            localStorage.setItem('access', action.payload.access);
            return {
                ...state,
                isAuthenticated: true,
                access: action.payload.access,
                refresh: action.payload.refresh,
                user: action.payload,
                login: true,
                error: '',
                
            };
        case ALL_USERS_SUCCESS:
            localStorage.removeItem('update');
            return {
                ...state,
                users: action.payload,
                signup: false,
                login: false
            };
        case SIGNUP_SUCCESS:
            return {
                ...state,
                signup: true,
                login: false,
                error: ''
            };
        case USER_LOADED_SUCCESS:
            localStorage.setItem('ad', action.payload.is_admin);
            localStorage.setItem('user', action.payload.brand._id);
            return {
                ...state,
                user: action.payload,
                signup: false,
                login: false,
                
            };
        case USER_LOADED_FAIL:
            return {
                ...state,
                user: null,
                signup: false,
                login: false,
                error: '',
                
            };
        case AUTHENTICATED_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                signup: false,
                login: false
            };
        case LOGIN_FAIL:
        case LOGOUT:
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            localStorage.removeItem('ad');
            localStorage.removeItem('user');
            localStorage.removeItem('update');
            return {
                ...state,
                access: null,
                refresh: null,
                isAuthenticated: false,
                user: null,
                error: action.payload,
                signup: false,
                login: false,
            };

        case PASSWORD_RESET_CONFIRM_SUCCESS:
        case PASSWORD_RESET_SUCCESS:
            localStorage.setItem('success', true);
            return {
                ...state,
                error: ''
            };
        case PASSWORD_RESET_FAIL:
        case PASSWORD_RESET_CONFIRM_FAIL:
        case SET_PASSWORD_SUCCESS:
        case SET_PASSWORD_FAIL:
        case ACTIVATION_SUCCESS:
        case ACTIVATION_FAIL:
        case ALL_USERS_FAIL:
        case SIGNUP_FAIL:
            localStorage.removeItem('update');
            return {
                ...state,
                error: action.payload,
                signup: false,
                login: false
            };
        default:
            return state;
    }
};

export const userUpdateReducer = (state = { user: {} }, action) => {
    switch (action.type) {
        case UPDATE_USER_REQUEST:
            return { loading: true };

        case UPDATE_USER_SUCCESS:
            return ({ loading: false, success: true, user: action.payload }, localStorage.setItem('update', true));

        case UPDATE_USER_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const userInactiveReducer = (state = { user: {} }, action) => {
    switch (action.type) {
        case INACTIVE_USER_REQUEST:
            return { loading: true };

        case INACTIVE_USER_SUCCESS:
            return { loading: false, success: true, user: action.payload };

        case INACTIVE_USER_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};


export const userDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_USER_REQUEST:
            return { loading: true };

        case DELETE_USER_SUCCESS:
            return { loading: false, success: true };

        case DELETE_USER_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const singleUserReducer = (state = { user: { brand: [], company: [] } }, action) => {
    switch (action.type) {
        case GET_USER_REQUEST:
            return { loading: true, ...state };

        case GET_USER_SUCCESS:
            return { loading: false, user: action.payload };

        case GET_USER_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const allcompaniesReducer = (state = { companies: [] }, action) => {
    switch (action.type) {
        case GET_COMPANYS_REQUEST:
            return { loading: true, companies: [] };

        case GET_COMPANYS_SUCCESS:
            return { loading: false, companies: action.payload.companies };

        case GET_COMPANYS_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const companyReducer = (state = { company: { brands: [] } }, action) => {
    switch (action.type) {
        case GET_COMPANY_REQUEST:
            return { loading: true, ...state };

        case GET_COMPANY_SUCCESS:
            return { loading: false, company: action.payload };

        case GET_COMPANY_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const companyCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case REGISTER_COMPANY_REQUEST:
            return { loading: true };

        case REGISTER_COMPANY_SUCCESS:
            return ({ loading: false, success: true, company: action.payload });

        case REGISTER_COMPANY_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const allbrandsReducer = (state = { brands: [] }, action) => {
    switch (action.type) {
        case GET_BRANDS_REQUEST:
            return { loading: true, brands: [] };

        case GET_BRANDS_SUCCESS:
            return { loading: false, brands: action.payload.brands };

        case GET_BRANDS_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const brandReducer = (state = { brand: { user: [], category: [] } }, action) => {
    switch (action.type) {
        case GET_BRAND_REQUEST:
            return { loading: true, ...state };

        case GET_BRAND_SUCCESS:
            return { loading: false, brand: action.payload };

        case GET_BRAND_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};


export const allpackageProfitsReducer = (state = { packageProfits: [] }, action) => {
    switch (action.type) {
        case GET_ALL_PACKAGE_PROFIT_REQUEST:
            return { loading: true, packageProfits: [] };

        case GET_ALL_PACKAGE_PROFIT_SUCCESS:
            return { loading: false, packageProfits: action.payload };

        case GET_ALL_PACKAGE_PROFIT_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const packageProfitReducer = (state = { packageProfit: {} }, action) => {
    switch (action.type) {
        case GET_PACKAGE_PROFIT_REQUEST:
            return { loading: true, ...state };

        case GET_PACKAGE_PROFIT_SUCCESS:
            return { loading: false, packageProfit: action.payload };

        case GET_PACKAGE_PROFIT_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};
export const createpackageProfitsReducer = (state = { packageProfit: [] }, action) => {
    switch (action.type) {
        case CREATE_PACKAGE_PROFIT_REQUEST:
            return { loading: true, packageProfit: [] };

        case CREATE_PACKAGE_PROFIT_SUCCESS:
            return { loading: false, packageProfit: action.payload };

        case CREATE_PACKAGE_PROFIT_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};
export const updatepackageProfitsReducer = (state = { packageProfit: [] }, action) => {
    switch (action.type) {
        case UPDATE_PACKAGE_PROFIT_REQUEST:
            return { loading: true, packageProfit: [] };

        case UPDATE_PACKAGE_PROFIT_SUCCESS:
            return { loading: false, packageProfit: action.payload };

        case UPDATE_PACKAGE_PROFIT_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};
export const deletepackageProfitsReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_PACKAGE_PROFIT_REQUEST:
            return { loading: true };

        case DELETE_PACKAGE_PROFIT_SUCCESS:
            return { loading: false, success: true };

        case DELETE_PACKAGE_PROFIT_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const packageCategoryCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_PACKAGE_CATEGORY_REQUEST:
            return { loading: true };

        case CREATE_PACKAGE_CATEGORY_SUCCESS:
            return { loading: false, success: true, packageCate: action.payload };

        case CREATE_PACKAGE_CATEGORY_FAIL:
            return { loading: false, error: action.payload };

        case CREATE_PACKAGE_CATEGORY_RESET:
            return {};

        default:
            return state;
    }
};

export const packCostReducer = (state = { packCost: { } }, action) => {
    switch (action.type) {
        case GET_PACK_COST_REQUEST:
            return { loading: true, ...state };

        case GET_PACK_COST_SUCCESS:
            return { loading: false, packCost: action.payload };

        case GET_PACK_COST_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const allpackagesReducer = (state = { packages: [] }, action) => {
    switch (action.type) {
        case GET_PACKAGES_REQUEST:
            return { loading: true, packages: [] };

        case GET_PACKAGES_SUCCESS:
            return { loading: false, packages: action.payload };

        case GET_PACKAGES_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const packageReducer = (state = { pack: { Ingrediants: [] }, webPack: {} }, action) => {
    switch (action.type) {
        case GET_PACKAGE_REQUEST:
            return { loading: true, ...state };

        case GET_PACKAGE_SUCCESS:
            return { loading: false, pack: action.payload, webPack: action.webpayload };

        case GET_PACKAGE_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const duplicatePackageReducer = (state = { pack: { Ingrediants: [] } }, action) => {
    switch (action.type) {
        case DUPLICATE_PACKAGE_REQUEST:
            return { loading: true, ...state };

        case DUPLICATE_PACKAGE_SUCCESS:
            return { loading: false, pack: action.payload };

        case DUPLICATE_PACKAGE_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const packageCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_PACKAGE_REQUEST:
            return { loading: true };

        case CREATE_PACKAGE_SUCCESS:
            return { loading: false, success: true, package: action.payload };

        case CREATE_PACKAGE_FAIL:
            return { loading: false, error: action.payload };

        case CREATE_PACKAGE_RESET:
            return {};

        default:
            return state;
    }
};

export const packageUpdateReducer = (state = { package: {} }, action) => {
    switch (action.type) {
        case UPDATE_PACKAGE_REQUEST:
            return { loading: true };

        case UPDATE_PACKAGE_SUCCESS:
            return { loading: false, success: true, package: action.payload };

        case UPDATE_PACKAGE_FAIL:
            return { loading: false, error: action.payload };

        case UPDATE_PACKAGE_RESET:
            return { category: {} };

        default:
            return state;
    }
};

export const packageDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_PACKAGE_REQUEST:
            return { loading: true };

        case DELETE_PACKAGE_SUCCESS:
            return { loading: false, success: true };

        case DELETE_PACKAGE_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const allpackageVariationsReducer = (state = { variations: [] }, action) => {
    switch (action.type) {
        case GET_PACKAGE_VARIATIONS_REQUEST:
            return { loading: true, variations: [] };

        case GET_PACKAGE_VARIATIONS_SUCCESS:
            return { loading: false, variations: action.payload };

        case GET_PACKAGE_VARIATIONS_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const packageVariationReducer = (state = { variation: { Ingrediants: [] }, Webvariation: {} }, action) => {
    switch (action.type) {
        case GET_PACKAGE_VARIATION_REQUEST:
            return { loading: true, ...state };

        case GET_PACKAGE_VARIATION_SUCCESS:
            return { loading: false, variation: action.payload, Webvariation: action.webpayload };

        case GET_PACKAGE_VARIATION_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const duplicatePackageVariationReducer = (state = { variation: { Ingrediants: [] } }, action) => {
    switch (action.type) {
        case DUPLICATE_PACKAGE_VARIATION_REQUEST:
            return { loading: true, ...state };

        case DUPLICATE_PACKAGE_VARIATION_SUCCESS:
            return { loading: false, variation: action.payload };

        case DUPLICATE_PACKAGE_VARIATION_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const packageVariationCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_PACKAGE_VARIATION_REQUEST:
            return { loading: true };

        case CREATE_PACKAGE_VARIATION_SUCCESS:
            return { loading: false, success: true, variation: action.payload };

        case CREATE_PACKAGE_VARIATION_FAIL:
            return { loading: false, error: action.payload };

        case CREATE_PACKAGE_VARIATION_RESET:
            return {};

        default:
            return state;
    }
};

export const packageVariationUpdateReducer = (state = { variation: {} }, action) => {
    switch (action.type) {
        case UPDATE_PACKAGE_VARIATION_REQUEST:
            return { loading: true };

        case UPDATE_PACKAGE_VARIATION_SUCCESS:
            return { loading: false, success: true, variation: action.payload };

        case UPDATE_PACKAGE_VARIATION_FAIL:
            return { loading: false, error: action.payload };

        case UPDATE_PACKAGE_VARIATION_RESET:
            return { category: {} };

        default:
            return state;
    }
};

export const packageVariationDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_PACKAGE_VARIATION_REQUEST:
            return { loading: true };

        case DELETE_PACKAGE_VARIATION_SUCCESS:
            return { loading: false, success: true };

        case DELETE_PACKAGE_VARIATION_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};


export const vendorReducer = (state = { vendor: {} }, action) => {
    switch (action.type) {
        case GET_VENDORS_REQUEST:
            return { loading: true, ...state };

        case GET_VENDORS_SUCCESS:
            return { loading: false, vendor: action.payload };

        case GET_VENDORS_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const vendorCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_VENDOR_REQUEST:
            return { loading: true };

        case CREATE_VENDOR_SUCCESS:
            return { loading: false, success: true, vendorcreat: action.payload };

        case CREATE_VENDOR_FAIL:
            return { loading: false, error: action.payload };

        case CREATE_VENDOR_RESET:
            return {};

        default:
            return state;
    }
};

export const unitCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_UNIT_REQUEST:
            return { loading: true };

        case CREATE_UNIT_SUCCESS:
            return { loading: false, success: true, unitcreat: action.payload };

        case CREATE_UNIT_FAIL:
            return { loading: false, error: action.payload };

        case CREATE_UNIT_RESET:
            return {};

        default:
            return state;
    }
};

export const unitReducer = (state = { unit: {} }, action) => {
    switch (action.type) {
        case GET_UNIT_REQUEST:
            return { loading: true, ...state };

        case GET_UNIT_SUCCESS:
            return { loading: false, unit: action.payload };

        case GET_UNIT_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};


export const purchaseReducer = (state = { purchase: {} }, action) => {
    switch (action.type) {
        case GET_PURCHASE_REQUEST:
            return { loading: true, ...state };

        case GET_PURCHASE_SUCCESS:
            return { loading: false, purchase: action.payload };

        case GET_PURCHASE_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const categoryReducer = (state = { category: { updates: [] } }, action) => {
    switch (action.type) {
        case GET_CATEGORY_REQUEST:
            return { loading: true, ...state };

        case GET_CATEGORY_SUCCESS:
            return { loading: false, category: action.payload };

        case GET_CATEGORY_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const allCategoryReducer = (state = { categories: { }, purchases :{ } }, action) => {
    switch (action.type) {
        case GET_ALL_CATEGORY_REQUEST:
            return { loading: true, ...state };

        case GET_ALL_CATEGORY_SUCCESS:
            return { loading: false, categories: action.payload.category, purchases: action.payload.vendor};

        case GET_ALL_CATEGORY_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};


export const categoryCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_CATEGORY_REQUEST:
            return { loading: true };

        case CREATE_CATEGORY_SUCCESS:
            return { loading: false, success: true, category: action.payload };

        case CREATE_CATEGORY_FAIL:
            return { loading: false, error: action.payload };

        case CREATE_CATEGORY_RESET:
            return {};

        default:
            return state;
    }
};

export const categoryUpdateReducer = (state = { category: {} }, action) => {
    switch (action.type) {
        case UPDATE_CATEGORY_REQUEST:
            return { loading: true };

        case UPDATE_CATEGORY_SUCCESS:
            return { loading: false, success: true, category: action.payload };

        case UPDATE_CATEGORY_FAIL:
            return { loading: false, error: action.payload };

        case UPDATE_CATEGORY_RESET:
            return { category: {} };

        default:
            return state;
    }
};

export const categoryDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_CATEGORY_REQUEST:
            return { loading: true };

        case DELETE_CATEGORY_SUCCESS:
            return { loading: false, success: true };

        case DELETE_CATEGORY_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const cartReducer = (state = initialStateCart, action) => {
    switch (action.type) {
        case CART_ADD_ITEM:
            const item = action.payload;
            const existItem = state.cartItems.find((x) => x.variation === item.variation);

            if (existItem) {
                return {
                    ...state,
                    cartItems: state.cartItems.map((x) => (x.variation === existItem.variation ? item : x))
                };
            } else {
                return {
                    ...state,
                    cartItems: [...state.cartItems, item]
                };
            }

        case CART_REMOVE_ITEM:
            return {
                ...state,
                cartItems: state.cartItems.filter((x) => x.variation !== action.payload)
            };

        case CART_SAVE_SHIPPING_ADDRESS:
            return {
                ...state,
                shippingAddress: action.payload
            };

        case CART_CLEAR_ITEMS:
            return {
                ...state,
                cartItems: []
            };

        case CART_DELETE_SHIPPING_ADDRESS:
            return {
                ...state,
                shippingAddress: []
            };
        default:
            return state;
    }
};

export const orderCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case ORDER_CREATE_REQUEST:
            return {
                loading: true
            };

        case ORDER_CREATE_SUCCESS:
            return {
                loading: false,
                success: true,
                order: action.payload
            };

        case ORDER_CREATE_FAIL:
            return {
                loading: false,
                error: action.payload
            };

        case ORDER_CREATE_RESET:
            return {};

        default:
            return state;
    }
};

export const orderDetailsReducer = (state = { loading: true, orderItems: [], shippingAddress: {} }, action) => {
    switch (action.type) {
        case ORDER_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            };

        case ORDER_DETAILS_SUCCESS:
            return {
                loading: false,
                order: action.payload
            };

        case ORDER_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload
            };

        default:
            return state;
    }
};

export const orderPayReducer = (state = {}, action) => {
    switch (action.type) {
        case ORDER_PAY_REQUEST:
            return {
                loading: true
            };

        case ORDER_PAY_SUCCESS:
            return {
                loading: false,
                success: true
            };

        case ORDER_PAY_FAIL:
            return {
                loading: false,
                error: action.payload
            };

        case ORDER_PAY_RESET:
            return {};

        default:
            return state;
    }
};

export const orderDeliverReducer = (state = {}, action) => {
    switch (action.type) {
        case ORDER_DELIVERED_REQUEST:
            return {
                loading: true
            };

        case ORDER_DELIVERED_SUCCESS:
            return {
                loading: false,
                success: true
            };

        case ORDER_DELIVERED_FAIL:
            return {
                loading: false,
                error: action.payload
            };

        case ORDER_DELIVERED_RESET:
            return {};

        default:
            return state;
    }
};

export const orderListMyReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
        case ORDER_LIST_MY_REQUEST:
            return {
                loading: true
            };

        case ORDER_LIST_MY_SUCCESS:
            return {
                loading: false,
                orders: action.payload
            };

        case ORDER_LIST_MY_FAIL:
            return {
                loading: false,
                error: action.payload
            };

        case ORDER_LIST_MY_RESET:
            return { orders: [] };

        default:
            return state;
    }
};

export const orderListReducer = (state = { loading: true, orderItems: [], shippingAddress: {} }, action) => {
    switch (action.type) {
        case ORDER_LIST_REQUEST:
            return {
                loading: true
            };

        case ORDER_LIST_SUCCESS:
            return {
                loading: false,
                orders: action.payload
            };

        case ORDER_LIST_FAIL:
            return {
                loading: false,
                error: action.payload
            };

        default:
            return state;
    }
};

export const updateReducer = (state = { updates: [] }, action) => {
    switch (action.type) {
        case GET_UPDATES_REQUEST:
            return { loading: true, updates: [] };

        case GET_UPDATES_SUCCESS:
            return { loading: false, updates: action.payload.updates };

        case GET_UPDATES_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};



export const quotationCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case QUOTATION_CREATE_REQUEST:
            return {
                loading: true
            };

        case QUOTATION_CREATE_SUCCESS:
            localStorage.setItem('create', true)
            return {
                loading: false,
                success: true,
                quotation: action.payload
            };

        case QUOTATION_CREATE_FAIL:
            return {
                loading: false,
                error: action.payload
            };

        default:
            return state;
    }
};


export const allquotationsReducer = (state = { quotations: [] }, action) => {
    switch (action.type) {
        case QUOTATION_ALL_REQUEST:
            return { loading: true, quotations: [] };

        case QUOTATION_ALL_SUCCESS:
            return { loading: false, quotations: action.payload };

        case QUOTATION_ALL_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const quotationReducer = (state = { quotation: { items: []} }, action) => {
    switch (action.type) {
        case QUOTATION_DETAIL_REQUEST:
            return { loading: true, ...state };

        case QUOTATION_DETAIL_SUCCESS:
            return { loading: false, quotation: action.payload };

        case QUOTATION_DETAIL_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};


export const duplicateQuotationReducer = (state = { quotation: { items: [] } }, action) => {
    switch (action.type) {
        case DUPLICATE_QUOTATION_REQUEST:
            return { loading: true, ...state };

        case DUPLICATE_QUOTATION_SUCCESS:
            return { loading: false, quotation: action.payload };

        case DUPLICATE_QUOTATION_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const quotationUpdateReducer = (state = { quotation: {} }, action) => {
    switch (action.type) {
        case UPDATE_QUOTATION_REQUEST:
            return { loading: true };

        case UPDATE_QUOTATION_SUCCESS:
            return { loading: false, success: true, quotation: action.payload };

        case UPDATE_QUOTATION_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const quotationDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_QUOTATION_REQUEST:
            return { loading: true };

        case DELETE_QUOTATION_SUCCESS:
            return { loading: false, success: true };

        case DELETE_QUOTATION_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};






export const allclientsReducer = (state = { clients: [] }, action) => {
    switch (action.type) {
        case CLIENT_ALL_REQUEST:
            return { loading: true, clients: [] };

        case CLIENT_ALL_SUCCESS:
            return { loading: false, clients: action.payload };

        case CLIENT_ALL_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const clientReducer = (state = { client: {} }, action) => {
    switch (action.type) {
        case CLIENT_DETAIL_REQUEST:
            return { loading: true, ...state };

        case CLIENT_DETAIL_SUCCESS:
            return { loading: false, client: action.payload };

        case CLIENT_DETAIL_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};