// assets
import { DashboardOutlined } from '@ant-design/icons';
import { BsCart3, BsPeopleFill, BsBasket2Fill,BsUiChecks, BsGraphUp } from "react-icons/bs";
import { TfiPackage } from "react-icons/tfi";
import { GiStockpiles } from "react-icons/gi";
import { FaUsers, FaFileInvoice } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";

// icons
const icons = {
    DashboardOutlined,
    BsBasket2Fill,
    BsCart3,
    BsUiChecks,
    TfiPackage,
    GiStockpiles,
    FaUsers,
    FaFileInvoice,
    BsGraphUp,
    BsPeopleFill,
    FiSettings
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
    id: 'group-dashboard',
    title: 'Navigation',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '',
            icon: icons.DashboardOutlined,
            breadcrumbs: false
        },
        {
            id: 'Product',
            title: 'Product',
            type: 'item',
            url: '/products',
            icon: icons.BsBasket2Fill
        },
        {
            id: 'Profit',
            title: 'Profit',
            type: 'item',
            url: '/profits',
            icon: icons.BsGraphUp
        },
        {
            id: 'Stock',
            title: 'Stock',
            type: 'item',
            url: '/allstocks',
            icon: icons.GiStockpiles,
            breadcrumbs: false
        },
        {
            id: 'Cart',
            title: 'Cart',
            type: 'item',
            url: '/cart',
            icon: icons.BsCart3,
            breadcrumbs: false
        },
        {
            id: 'All Orders',
            title: 'All Orders',
            type: 'item',
            url: '/allorders',
            icon: icons.BsUiChecks,
            breadcrumbs: false
        },
        {
            id: 'All Packages',
            title: 'All Packages',
            type: 'item',
            url: '/allpackages',
            icon: icons.TfiPackage,
            breadcrumbs: false
        },
        {
            id: 'Quotation',
            title: 'Quotation / Invoice',
            type: 'item',
            url: '/allquotations',
            icon: icons.FaFileInvoice,
            breadcrumbs: false
        },
        {
            id: 'All Users',
            title: 'All Users',
            type: 'item',
            url: '/allusers',
            icon: icons.FaUsers,
            breadcrumbs: false
        },
        {
            id: 'All Clients',
            title: 'Customers',
            type: 'item',
            url: '/allclients',
            icon: icons.BsPeopleFill,
            breadcrumbs: false
        },
        {
            id: 'settings',
            title: 'Settings',
            type: 'item',
            url: '/settings',
            icon: icons.FiSettings,
            breadcrumbs: false
        },
    ]
};

export default dashboard;
