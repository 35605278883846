import axios from 'axios';
import {
    REGISTER_COMPANY_REQUEST,
    REGISTER_COMPANY_SUCCESS,
    REGISTER_COMPANY_FAIL,
    GET_COMPANYS_REQUEST,
    GET_COMPANYS_SUCCESS,
    GET_COMPANYS_FAIL,
    GET_COMPANY_REQUEST,
    GET_COMPANY_SUCCESS,
    GET_COMPANY_FAIL,
    GET_BRANDS_REQUEST,
    GET_BRANDS_SUCCESS,
    GET_BRANDS_FAIL,
    GET_BRAND_REQUEST,
    GET_BRAND_SUCCESS,
    GET_BRAND_FAIL,
    GET_UPDATES_REQUEST,
    GET_UPDATES_SUCCESS,
    GET_UPDATES_FAIL,
    GET_ALL_PACKAGE_PROFIT_REQUEST,
    GET_ALL_PACKAGE_PROFIT_SUCCESS,
    GET_ALL_PACKAGE_PROFIT_FAIL,
    GET_PACKAGE_PROFIT_REQUEST,
    GET_PACKAGE_PROFIT_SUCCESS,
    GET_PACKAGE_PROFIT_FAIL,
    CREATE_PACKAGE_PROFIT_REQUEST,
    CREATE_PACKAGE_PROFIT_SUCCESS,
    CREATE_PACKAGE_PROFIT_FAIL,
    UPDATE_PACKAGE_PROFIT_REQUEST,
    UPDATE_PACKAGE_PROFIT_SUCCESS,
    UPDATE_PACKAGE_PROFIT_FAIL,
    DELETE_PACKAGE_PROFIT_REQUEST,
    DELETE_PACKAGE_PROFIT_SUCCESS,
    DELETE_PACKAGE_PROFIT_FAIL,
    CREATE_PACKAGE_CATEGORY_REQUEST,
    CREATE_PACKAGE_CATEGORY_SUCCESS,
    CREATE_PACKAGE_CATEGORY_FAIL,
    GET_PACKAGES_REQUEST,
    GET_PACKAGES_SUCCESS,
    GET_PACKAGES_FAIL,
    GET_PACKAGE_REQUEST,
    GET_PACKAGE_SUCCESS,
    GET_PACKAGE_FAIL,
    DUPLICATE_PACKAGE_REQUEST,
    DUPLICATE_PACKAGE_SUCCESS,
    DUPLICATE_PACKAGE_FAIL,
    UPDATE_PACKAGE_REQUEST,
    UPDATE_PACKAGE_SUCCESS,
    UPDATE_PACKAGE_FAIL,
    DELETE_PACKAGE_REQUEST,
    DELETE_PACKAGE_SUCCESS,
    DELETE_PACKAGE_FAIL,
    GET_PACKAGE_VARIATIONS_REQUEST,
    GET_PACKAGE_VARIATIONS_SUCCESS,
    GET_PACKAGE_VARIATIONS_FAIL,
    GET_PACKAGE_VARIATION_REQUEST,
    GET_PACKAGE_VARIATION_SUCCESS,
    GET_PACKAGE_VARIATION_FAIL,
    DUPLICATE_PACKAGE_VARIATION_REQUEST,
    DUPLICATE_PACKAGE_VARIATION_SUCCESS,
    DUPLICATE_PACKAGE_VARIATION_FAIL,
    UPDATE_PACKAGE_VARIATION_REQUEST,
    UPDATE_PACKAGE_VARIATION_SUCCESS,
    UPDATE_PACKAGE_VARIATION_FAIL,
    UPDATE_PACKAGE_VARIATION_RESET,
    DELETE_PACKAGE_VARIATION_REQUEST,
    DELETE_PACKAGE_VARIATION_SUCCESS,
    DELETE_PACKAGE_VARIATION_FAIL,
    CREATE_PACKAGE_VARIATION_REQUEST,
    CREATE_PACKAGE_VARIATION_SUCCESS,
    CREATE_PACKAGE_VARIATION_FAIL,
    CREATE_PACKAGE_VARIATION_RESET,
    GET_PACK_COST_REQUEST,
    GET_PACK_COST_SUCCESS,
    GET_PACK_COST_FAIL,
    CREATE_PACKAGE_REQUEST,
    CREATE_PACKAGE_SUCCESS,
    CREATE_PACKAGE_FAIL,
    GET_VENDORS_REQUEST,
    GET_VENDORS_SUCCESS,
    GET_VENDORS_FAIL,
    CREATE_VENDOR_REQUEST,
    CREATE_VENDOR_SUCCESS,
    CREATE_VENDOR_FAIL,
    CREATE_UNIT_REQUEST,
    CREATE_UNIT_SUCCESS,
    CREATE_UNIT_FAIL,
    GET_UNIT_REQUEST,
    GET_UNIT_SUCCESS,
    GET_UNIT_FAIL,
    GET_PURCHASE_REQUEST,
    GET_PURCHASE_SUCCESS,
    GET_PURCHASE_FAIL,
    GET_CATEGORY_REQUEST,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_FAIL,
    GET_ALL_CATEGORY_REQUEST,
    GET_ALL_CATEGORY_SUCCESS,
    GET_ALL_CATEGORY_FAIL,
    CREATE_CATEGORY_REQUEST,
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_FAIL,
    UPDATE_CATEGORY_REQUEST,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_FAIL,
    DELETE_CATEGORY_REQUEST,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    ALL_USERS_SUCCESS,
    ALL_USERS_FAIL,
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USER_FAIL,
    ACTIVATION_SUCCESS,
    ACTIVATION_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    LOGOUT,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    SET_PASSWORD_SUCCESS,
    SET_PASSWORD_FAIL,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,
    INACTIVE_USER_FAIL,
    INACTIVE_USER_REQUEST,
    INACTIVE_USER_SUCCESS,
    DELETE_USER_FAIL,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_SAVE_SHIPPING_ADDRESS,
    CART_DELETE_SHIPPING_ADDRESS,
    CART_CLEAR_ITEMS,
    ORDER_CREATE_REQUEST,
    ORDER_CREATE_SUCCESS,
    ORDER_CREATE_FAIL,
    ORDER_DETAILS_REQUEST,
    ORDER_DETAILS_SUCCESS,
    ORDER_DETAILS_FAIL,
    ORDER_PAY_REQUEST,
    ORDER_PAY_SUCCESS,
    ORDER_PAY_FAIL,
    ORDER_LIST_MY_REQUEST,
    ORDER_LIST_MY_SUCCESS,
    ORDER_LIST_MY_FAIL,
    ORDER_DELIVERED_REQUEST,
    ORDER_DELIVERED_SUCCESS,
    ORDER_DELIVERED_FAIL,
    ORDER_LIST_REQUEST,
    ORDER_LIST_SUCCESS,
    ORDER_LIST_FAIL,

    QUOTATION_CREATE_REQUEST,
    QUOTATION_CREATE_SUCCESS,
    QUOTATION_CREATE_FAIL,
    QUOTATION_ALL_REQUEST,
    QUOTATION_ALL_SUCCESS,
    QUOTATION_ALL_FAIL,
    QUOTATION_DETAIL_REQUEST,
    QUOTATION_DETAIL_SUCCESS,
    QUOTATION_DETAIL_FAIL,
    DUPLICATE_QUOTATION_REQUEST,
    DUPLICATE_QUOTATION_SUCCESS,
    DUPLICATE_QUOTATION_FAIL,
    UPDATE_QUOTATION_REQUEST,
    UPDATE_QUOTATION_SUCCESS,
    UPDATE_QUOTATION_FAIL,
    DELETE_QUOTATION_REQUEST,
    DELETE_QUOTATION_SUCCESS,
    DELETE_QUOTATION_FAIL,

    CLIENT_ALL_REQUEST,
    CLIENT_ALL_SUCCESS,
    CLIENT_ALL_FAIL,
    CLIENT_DETAIL_REQUEST,
    CLIENT_DETAIL_SUCCESS,
    CLIENT_DETAIL_FAIL,
} from '../Constants/Constants';

import OAuth from 'oauth-1.0a';


// const oauth = new OAuth({
//     consumer: {
//       key: 'ck_57cd87fc8b88a7d95307eb303581980f72c604c9',
//       secret: 'cs_d280775c988569ad3a8243a4c82d3f6ed3a39647'
//     },
//     signature_method: 'HMAC-SHA1'
// });



export const checkAuthenticated = () => async (dispatch) => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        };

        const body = JSON.stringify({ token: localStorage.getItem('access') });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL_PROD}/auth/jwt/verify/`, body, config);

            if (res.data.code !== 'token_not_valid') {
                dispatch({
                    type: AUTHENTICATED_SUCCESS
                });
            } else {
                dispatch({
                    type: AUTHENTICATED_FAIL
                });
            }
        } catch (err) {
            dispatch({
                type: AUTHENTICATED_FAIL
            });
        }
    } else {
        dispatch({
            type: AUTHENTICATED_FAIL
        });
    }
};

export const load_user = () => async (dispatch) => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('access')}`,
                Accept: 'application/json'
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/user/`, config);

            dispatch({
                type: USER_LOADED_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: USER_LOADED_FAIL
            });
        }
    } else {
        dispatch({
            type: USER_LOADED_FAIL
        });
    }
};

export const login = (email, password) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ email, password });

    try {
        localStorage.setItem('load', true)
        const res = await axios.post(`${process.env.REACT_APP_API_URL_PROD}/auth/jwt/create/`, body, config);

        localStorage.removeItem('load')
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });

        dispatch(load_user());
    } catch (error) {
        localStorage.removeItem('load')
        dispatch({
            type: LOGIN_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const signup = (first_name, last_name, name, email, password, re_password, brand, company, is_staff, is_admin, is_superuser) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ first_name, last_name, name, email, password, re_password, brand, company, is_staff, is_admin, is_superuser });

    try {
        localStorage.setItem('load', true)
        const res = await axios.post(`${process.env.REACT_APP_API_URL_PROD}/auth/users/`, body, config);
        localStorage.removeItem('load')
        dispatch({
            type: SIGNUP_SUCCESS,
            payload: res.data
        });
    } catch (error) {
        localStorage.removeItem('load')
        dispatch({
            type: SIGNUP_FAIL,
            payload: error.response && error.response.data ? error.response.data : error.message
        });
    }
};

export const alluseraccounts = () => async (dispatch) => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('access')}`,

                Accept: 'application/json'
            }
        };

        try {
            localStorage.setItem('load', true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/user/`, config);
            localStorage.removeItem('load')
            dispatch({
                type: ALL_USERS_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: ALL_USERS_FAIL
            });
        }
    } else {
        localStorage.removeItem('load')
        dispatch({
            type: ALL_USERS_FAIL
        });
    }
};

export const verify = (uid, token) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ uid, token });

    try {
        await axios.post(`${process.env.REACT_APP_API_URL_PROD}/auth/users/activation/`, body, config);

        dispatch({
            type: ACTIVATION_SUCCESS
        });

        dispatch(load_user());
    } catch (err) {
        dispatch({
            type: ACTIVATION_FAIL
        });
    }
};

export const reset_password = (email) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ email });

    try {
        localStorage.setItem('load', true)
        await axios.post(`${process.env.REACT_APP_API_URL_PROD}/auth/users/reset_password/`, body, config);
        localStorage.removeItem('load')
        dispatch({
            type: PASSWORD_RESET_SUCCESS,
            payload: 'success'
        });

        dispatch(load_user());
    } catch (error) {
        localStorage.removeItem('load')
        dispatch({
            type: PASSWORD_RESET_FAIL,
            payload: error.response && error.response.data ? error.response.data : error.message
        });
    }
};

export const reset_password_confirm = (uid, token, new_password, re_new_password) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ uid, token, new_password, re_new_password });

    try {
        localStorage.setItem('load', true)
        await axios.post(`${process.env.REACT_APP_API_URL_PROD}/auth/users/reset_password_confirm/`, body, config);
        localStorage.removeItem('load')

        dispatch({
            type: PASSWORD_RESET_CONFIRM_SUCCESS
        });

        dispatch(load_user());
    } catch (error) {
        localStorage.removeItem('load')
        dispatch({
            type: PASSWORD_RESET_CONFIRM_FAIL,
            payload: error.response && error.response.data ? error.response.data : error.message
        });
    }
};

export const set_new_password = (current_password, new_password, re_new_password) => async (dispatch, getState) => {
    const {
        auth: { access }
    } = getState();

    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${access}`
        }
    };

    const body = JSON.stringify({ current_password, new_password, re_new_password });

    try {
        await axios.post(`${process.env.REACT_APP_API_URL_PROD}/auth/users/set_password/`, body, config);

        dispatch({
            type: SET_PASSWORD_SUCCESS
        });
    } catch (err) {
        dispatch({
            type: SET_PASSWORD_FAIL
        });
    }
};


export const updateSingleUser = (value) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_USER_REQUEST });
        const res = await axios.put(`${process.env.REACT_APP_API_URL_PROD}/api/user/update/${value.uid}/`, value);
        dispatch({
            type: UPDATE_USER_SUCCESS,
            payload: res.data
        });
    } catch (error) {
        dispatch({
            type: UPDATE_USER_FAIL,
            payload: error.response && error.response.data ? error.response.data : error.message
        });
    }
};


export const deleteUser = (uid) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_USER_REQUEST });
        await axios.delete(`${process.env.REACT_APP_API_URL_PROD}/api/user/delete/${uid}/`);
        dispatch({
            type: DELETE_USER_SUCCESS
        });
    } catch (error) {
        dispatch({
            type: DELETE_USER_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};


export const inactiveUser = (uid) => async (dispatch) => {
    try {
        dispatch({ type: INACTIVE_USER_REQUEST });
        const res = await axios.put(`${process.env.REACT_APP_API_URL_PROD}/api/user/inactive/${uid}/`);
        dispatch({
            type: INACTIVE_USER_SUCCESS,
            payload: res.data
        });
    } catch (error) {
        dispatch({
            type: INACTIVE_USER_FAIL,
            payload: error.response && error.response.data ? error.response.data : error.message
        });
    }
};


export const getSingleUser = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_USER_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/user/${id}/`);
        dispatch({
            type: GET_USER_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: GET_USER_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};


export const logout = () => (dispatch) => {
    dispatch({
        type: LOGOUT
    });
};






export const listCompanies = () => async (dispatch) => {
    try {
        dispatch({ type: GET_COMPANYS_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/company/`);
        dispatch({
            type: GET_COMPANYS_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: GET_COMPANYS_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const getCompany = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_COMPANY_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/company/${id}/`);
        dispatch({
            type: GET_COMPANY_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: GET_COMPANY_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};


export const createCompanyAction = (
        firstName
    ) => async (dispatch) => {
        
    try {
        dispatch({ type: REGISTER_COMPANY_REQUEST });

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL_PROD}/api/brand/create/newuser/`, firstName);
        dispatch({
            type: REGISTER_COMPANY_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: REGISTER_COMPANY_FAIL,
            payload: error.response && error.response.data ? error.response.data : error.message
        });
    }
};




export const listBrands = () => async (dispatch) => {
    try {
        dispatch({ type: GET_BRANDS_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/`);
        dispatch({
            type: GET_BRANDS_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: GET_BRANDS_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const getBrand = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_BRAND_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/brand/${id}/`);
        dispatch({
            type: GET_BRAND_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: GET_BRAND_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};


export const listPackageProfits = () =>
    async (dispatch) => {
        try {
            dispatch({ type: GET_ALL_PACKAGE_PROFIT_REQUEST });
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/package/profits/`);
            dispatch({
                type: GET_ALL_PACKAGE_PROFIT_SUCCESS,
                payload: data
            });
        } catch (error) {
            dispatch({
                type: GET_ALL_PACKAGE_PROFIT_FAIL,
                payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
            });
        }
    };

export const getPackageProfit = (ppid) => async (dispatch) => {
    try {
        dispatch({ type: GET_PACKAGE_PROFIT_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/package/profit/${ppid}/`);
        dispatch({
            type: GET_PACKAGE_PROFIT_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: GET_PACKAGE_PROFIT_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const createPackageProfit = (value) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_PACKAGE_PROFIT_REQUEST });

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL_PROD}/api/brand/create/package/profit/`, value);
        dispatch({
            type: CREATE_PACKAGE_PROFIT_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: CREATE_PACKAGE_PROFIT_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const updatePackageProfit = (value) => async (dispatch) => {

    let data = ''
    try {
        dispatch({ type: UPDATE_PACKAGE_PROFIT_REQUEST });

        {value.active !== undefined 
            ?
                data = await axios.put(`${process.env.REACT_APP_API_URL_PROD}/api/brand/update/package/profit/active/${value.uid}/`, value)
            :
                data = await axios.put(`${process.env.REACT_APP_API_URL_PROD}/api/brand/update/package/profit/${value.ppid}/`, value)
        }
        dispatch({
            type: UPDATE_PACKAGE_PROFIT_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: UPDATE_PACKAGE_PROFIT_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const deletePackageProfit = (value) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PACKAGE_PROFIT_REQUEST });
        await axios.put(`${process.env.REACT_APP_API_URL_PROD}/api/brand/delete/package/profit/${value.uid}/`, value);
        dispatch({
            type: DELETE_PACKAGE_PROFIT_SUCCESS
        });
    } catch (error) {
        dispatch({
            type: DELETE_PACKAGE_PROFIT_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const createPackageCategory = (value) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_PACKAGE_CATEGORY_REQUEST });

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL_PROD}/api/brand/create/packageCategory/`, value);
        dispatch({
            type: CREATE_PACKAGE_CATEGORY_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: CREATE_PACKAGE_CATEGORY_FAIL,
            payload: error.response && error.response.data ? error.response.data : error.message
        });
    }
};

export const getPackCost = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_PACK_COST_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/get_pack/cost/variation/${id}/`);
        dispatch({
            type: GET_PACK_COST_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: GET_PACK_COST_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const getPackageCost = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_PACK_COST_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/get_pack/cost/${id}/`);
        dispatch({
            type: GET_PACK_COST_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: GET_PACK_COST_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const listPackageVariations = () =>
    async (dispatch) => {
        try {
            dispatch({ type: GET_PACKAGE_VARIATIONS_REQUEST });
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/package/variations/`);
            dispatch({
                type: GET_PACKAGE_VARIATIONS_SUCCESS,
                payload: data
            });
        } catch (error) {
            dispatch({
                type: GET_PACKAGE_VARIATIONS_FAIL,
                payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
            });
        }
    };

export const getPackageVariation = (id, pid) => async (dispatch) => {
    try {
        dispatch({ type: GET_PACKAGE_VARIATION_REQUEST });
        // console.log(data.website_id, pid)
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/package/variation/${id}/`);
        console.log(data, pid)
        if (data.website_id !== null && pid !== 'null' && pid !== undefined && data.website_id !== '0'){
            axios.get(`${process.env.REACT_APP_WEBITE_URL_PROD}/products/${pid}/variations/${data.website_id}`).then(response => {
                dispatch({
                    type: GET_PACKAGE_VARIATION_SUCCESS,
                    payload: data,
                    webpayload: response.data
                });
            }).catch(error => {
                console.error(error);
            });
        } else if (data.website_id === null && pid !== 'null' && pid !== undefined){
            axios.get(`${process.env.REACT_APP_WEBITE_URL_PROD}/products/${pid}`).then(response => {
                dispatch({
                    type: GET_PACKAGE_VARIATION_SUCCESS,
                    payload: data,
                    webpayload: response.data
                });
            }).catch(error => {
                console.error(error);
            });
        } else {
            dispatch({
                type: GET_PACKAGE_VARIATION_SUCCESS,
                payload: data
            });
        }
    } catch (error) {
        dispatch({
            type: GET_PACKAGE_VARIATION_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const duplicatePackageVariation = (uid, pid) => async (dispatch) => {
    try {
        dispatch({ type: DUPLICATE_PACKAGE_VARIATION_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/duplicate_package/variation/${uid}/${pid}/`);
        dispatch({
            type: DUPLICATE_PACKAGE_VARIATION_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: DUPLICATE_PACKAGE_VARIATION_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const createPackageVariation = (value) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_PACKAGE_VARIATION_REQUEST });

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL_PROD}/api/brand/create/package/variation/`, value);
        dispatch({
            type: CREATE_PACKAGE_VARIATION_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: CREATE_PACKAGE_VARIATION_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const updatePackageVariation = (value) => async (dispatch) => {
    let data = ''
    try {
        dispatch({ type: UPDATE_PACKAGE_VARIATION_REQUEST });
        
        {value.pquantity !== undefined ?
            data = await axios.put(`${process.env.REACT_APP_API_URL_PROD}/api/brand/update/package/variation/${value.uid}/`, value)
        :
            data = await axios.put(`${process.env.REACT_APP_API_URL_PROD}/api/brand/update/package/variation/active/${value.uid}/`, value)
        }


        localStorage.setItem('updatepack', true)

        dispatch({
            type: UPDATE_PACKAGE_VARIATION_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: UPDATE_PACKAGE_VARIATION_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const updatePackageVariationDetail = (value) => async (dispatch) => {

    try {
        dispatch({ type: UPDATE_PACKAGE_VARIATION_REQUEST });

        const data = await axios.put(`${process.env.REACT_APP_API_URL_PROD}/api/brand/update/package/variation/detail/${value.uid}/`, value)


        if (data) {
            if (data.data.website_id !== null && value.wpid !== null && value.wpid !== 'null' && data.data.website_id !== 0){
                const payload = {
                    "regular_price": data.data.price.toString(),
                    "attributes": [
                        {
                            "id": 0,
                            "name": "Daig Weight",
                            "option": data.data.name
                        }
                    ]
                }
                axios.put(`${process.env.REACT_APP_WEBITE_URL_PROD}/products/${value.wpid}/variations/${data.data.website_id}`, payload).then(response => {
                    console.log(response.data)
                }).catch(error => {
                    console.error(error);
                });
            }
        }

        localStorage.setItem('updatepack', true)

        dispatch({
            type: UPDATE_PACKAGE_VARIATION_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: UPDATE_PACKAGE_VARIATION_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const deletePackageVariation = (value) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PACKAGE_VARIATION_REQUEST });
        await axios.put(`${process.env.REACT_APP_API_URL_PROD}/api/brand/delete/package/variation/${value.uid}/`, value);
        dispatch({
            type: DELETE_PACKAGE_VARIATION_SUCCESS
        });
    } catch (error) {
        dispatch({
            type: DELETE_PACKAGE_VARIATION_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const listPackages = (id, keyword = '', page = '', rowsPerPage= '', cust ) =>
    async (dispatch) => {
        try {
            dispatch({ type: GET_PACKAGES_REQUEST });
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/packages/${id}/${cust}/?keyword=${keyword}&page=${page}&perpage=${rowsPerPage}`);
            dispatch({
                type: GET_PACKAGES_SUCCESS,
                payload: data
            });
        } catch (error) {
            dispatch({
                type: GET_PACKAGES_FAIL,
                payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
            });
        }
    };

export const getPackage = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_PACKAGE_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/package/${id}/`);
        

        if (data.website_id !== null){
            axios.get(`${process.env.REACT_APP_WEBITE_URL_PROD}/products/${data.website_id}`).then(response => {
                dispatch({
                    type: GET_PACKAGE_SUCCESS,
                    payload: data,
                    webpayload: response.data
                });
            }).catch(error => {
                console.error(error);
            });
        } else {
            dispatch({
                type: GET_PACKAGE_SUCCESS,
                payload: data
            });
        }
        
    } catch (error) {
        dispatch({
            type: GET_PACKAGE_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const duplicatePackage = (id) => async (dispatch) => {
    try {
        dispatch({ type: DUPLICATE_PACKAGE_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/duplicate_package/${id}/`);
        dispatch({
            type: DUPLICATE_PACKAGE_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: DUPLICATE_PACKAGE_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const createPackage = (value) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_PACKAGE_REQUEST });

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL_PROD}/api/brand/create/package/`, value);
        dispatch({
            type: CREATE_PACKAGE_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: CREATE_PACKAGE_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const updatePackage = (value) => async (dispatch) => {
    let data = ''
    try {
        dispatch({ type: UPDATE_PACKAGE_REQUEST });
        
        {value.pquantity !== undefined ?
            data = await axios.put(`${process.env.REACT_APP_API_URL_PROD}/api/brand/update/package/${value.uid}/`, value)
        : 
            data = await axios.put(`${process.env.REACT_APP_API_URL_PROD}/api/brand/update/package/active/${value.uid}/`, value)
        }

        localStorage.setItem('updatepack', true)

        dispatch({
            type: UPDATE_PACKAGE_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: UPDATE_PACKAGE_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};


export const updatePackageDetail = (value) => async (dispatch) => {

    try {
        dispatch({ type: UPDATE_PACKAGE_REQUEST });

        const data = await axios.put(`${process.env.REACT_APP_API_URL_PROD}/api/brand/update/package/detail/${value.uid}/`, value)

        
        if (data) {
            if (value.website_id !== null && value.website_id !== 'null') {
                let payload
                if (value.type === 'single') {
                    payload = {
                        "regular_price": data.data.price.toString(),
                        "name": data.data.name,
                        "description": data.data.description,
                        "short_description": data.data.short_description
                    }
                } else {
                    payload = {
                        "name": data.data.name,
                        "description": data.data.description,
                        "short_description": data.data.short_description
                    }
                }
                axios.put(`${process.env.REACT_APP_WEBITE_URL_PROD}/products/${value.website_id}`, payload).then(response => {
                    console.log(response.data)
                }).catch(error => {
                    console.error(error);
                });
            }
        }

        localStorage.setItem('updatepack', true)

        dispatch({
            type: UPDATE_PACKAGE_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: UPDATE_PACKAGE_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const deletePackage = (value) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PACKAGE_REQUEST });
        await axios.put(`${process.env.REACT_APP_API_URL_PROD}/api/brand/delete/package/${value.uid}/`, value);
        dispatch({
            type: DELETE_PACKAGE_SUCCESS
        });
    } catch (error) {
        dispatch({
            type: DELETE_PACKAGE_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const deletePackages = (value) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PACKAGE_REQUEST });
        await axios.put(`${process.env.REACT_APP_API_URL_PROD}/api/brand/delete/packages/1/`, value);
        dispatch({
            type: DELETE_PACKAGE_SUCCESS
        });
    } catch (error) {
        dispatch({
            type: DELETE_PACKAGE_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const getVendor = () => async (dispatch) => {
    try {
        dispatch({ type: GET_VENDORS_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/vendors/`);
        dispatch({
            type: GET_VENDORS_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: GET_VENDORS_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const creatVendor = (value) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_VENDOR_REQUEST });

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL_PROD}/api/brand/create/vendor/`, value);
        dispatch({
            type: CREATE_VENDOR_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: CREATE_VENDOR_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const createUnit = (value) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_UNIT_REQUEST });

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL_PROD}/api/brand/create/unit/`, value);
        dispatch({
            type: CREATE_UNIT_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: CREATE_UNIT_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const getUnit = () => async (dispatch) => {
    try {
        dispatch({ type: GET_UNIT_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/unit/`);
        dispatch({
            type: GET_UNIT_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: GET_UNIT_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const getPurchase = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_PURCHASE_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/purchase/${id}/`);
        dispatch({
            type: GET_PURCHASE_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: GET_PURCHASE_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const getCategory = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_CATEGORY_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/category/${id}/`);
        dispatch({
            type: GET_CATEGORY_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: GET_CATEGORY_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const getCategoryReport = (value) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_CATEGORY_REQUEST });
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL_PROD}/api/brand/all/category/`, value);
        dispatch({
            type: GET_ALL_CATEGORY_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: GET_ALL_CATEGORY_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const createCategory = (value) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_CATEGORY_REQUEST });

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL_PROD}/api/brand/create/category/`, value);
        dispatch({
            type: CREATE_CATEGORY_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: CREATE_CATEGORY_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const updateCategory = (value) => async (dispatch) => {
    const data = ''
    try {
        dispatch({ type: UPDATE_CATEGORY_REQUEST });

        {value.cartData !== undefined ?
            data = await axios.put(
                `${process.env.REACT_APP_API_URL_PROD}/api/brand/update/purchase/category/${value.bid}/`,
                value
            )
        :
        value.uquantity !== undefined ?
            data = await axios.put(
                `${process.env.REACT_APP_API_URL_PROD}/api/brand/update/category/${value.bid}/${value.uid}/`,
                value
            )
        :
        value.active !== undefined ?
            data = await axios.put(
                `${process.env.REACT_APP_API_URL_PROD}/api/brand/update/category/active/${value.bid}/${value.uid}/`,
                value
            )
        :
        value.uvquantity !== undefined ?
            data = await axios.put(
                `${process.env.REACT_APP_API_URL_PROD}/api/brand/update/category/vendor/${value.bid}/${value.uid}/`,
                value
            )
        :
        value.comment !== undefined ?
            await axios.put(
                `${process.env.REACT_APP_API_URL_PROD}/api/brand/delete/category/purchase/${value.bid}/${value.uid}/${value.pid}/`, value
            )
        :
        value.paid !== undefined ?
            await axios.put(
                `${process.env.REACT_APP_API_URL_PROD}/api/brand/update/category/vendor/paid/${value.bid}/${value.uid}/`, value
            )
        :
            data = await axios.put(
                `${process.env.REACT_APP_API_URL_PROD}/api/brand/update/category/detail/${value.bid}/${value.uid}/`,
                value
            )
        }
        
        dispatch({
            type: UPDATE_CATEGORY_SUCCESS,
            payload: data
        });

        dispatch({
            type: GET_BRAND_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: UPDATE_CATEGORY_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const deleteCategory = (value) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_CATEGORY_REQUEST });
        await axios.put(`${process.env.REACT_APP_API_URL_PROD}/api/brand/delete/category/${value.uid}/`, value);
        dispatch({
            type: DELETE_CATEGORY_SUCCESS
        });
    } catch (error) {
        dispatch({
            type: DELETE_CATEGORY_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const addToCart = (bid, pid, packname, id, quantity, charges) => async (dispatch, getState) => {
    let data
    if (id === 0 ) {
        data = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/package/${pid}/`);
    } else {
        data = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/package//variation/${pid}/`);
    }

    dispatch({
        type: CART_ADD_ITEM,
        payload: {
            variation: data.id,
            name: data.name,
            ing: data.ingrediants,
            price: charges === 0 ? data.price : charges,
            quantity,
            brand: bid,
            package: pid,
            packageName: packname
        }
    });
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};

export const removeFromCart = (id) => (dispatch, getState) => {
    dispatch({
        type: CART_REMOVE_ITEM,
        payload: id
    });
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};

export const saveShippingAddress = (data) => (dispatch) => {
    dispatch({
        type: CART_SAVE_SHIPPING_ADDRESS,
        payload: data
    });
    localStorage.setItem('shippingAddress', JSON.stringify(data));
};

export const createOrder = (order) => async (dispatch) => {
    try {
        dispatch({
            type: ORDER_CREATE_REQUEST
        });

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('access')}`
            }
        };

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL_PROD}/api/brand/orders/add/`, order, config);

        dispatch({
            type: ORDER_CREATE_SUCCESS,
            payload: data
        });

        dispatch({
            type: CART_DELETE_SHIPPING_ADDRESS,
            payload: data
        });

        dispatch({
            type: CART_CLEAR_ITEMS,
            payload: data
        });

        localStorage.removeItem('cartItems');
        localStorage.removeItem('shippingAddress');
    } catch (error) {
        dispatch({
            type: ORDER_CREATE_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const getOrderDetails = (id) => async (dispatch) => {
    try {
        dispatch({
            type: ORDER_DETAILS_REQUEST
        });

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('access')}`
            }
        };

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/order/${id}/`, config);

        dispatch({
            type: ORDER_DETAILS_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: ORDER_DETAILS_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const payOrder = (id, paymentResult) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_PAY_REQUEST
        });

        const {
            userLogin: { userInfo }
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        };

        const { data } = await axios.put(`/api/orders/${id}/pay/`, paymentResult, config);

        dispatch({
            type: ORDER_PAY_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: ORDER_PAY_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const deliverOrder = (id) => async (dispatch) => {
    try {
        dispatch({
            type: ORDER_DELIVERED_REQUEST
        });

        const { data } = await axios.put(`${process.env.REACT_APP_API_URL_PROD}/api/brand/delivered/${id}/`);

        dispatch({
            type: ORDER_DELIVERED_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: ORDER_DELIVERED_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const listMyOrder = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_LIST_MY_REQUEST
        });

        const {
            userLogin: { userInfo }
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        };

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/orders/`, config);

        dispatch({
            type: ORDER_LIST_MY_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: ORDER_LIST_MY_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const listOrders = (id) => async (dispatch) => {
    try {
        dispatch({
            type: ORDER_LIST_REQUEST
        });

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('access')}`
            }
        };

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/orders/${id}/`, config);

        dispatch({
            type: ORDER_LIST_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: ORDER_LIST_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const listOrdersDetail = (value) => async (dispatch) => {
    try {
        dispatch({
            type: ORDER_LIST_REQUEST
        });

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('access')}`
            }
        };

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL_PROD}/api/brand/orders/detail/${value.bid}/`, value, config);

        dispatch({
            type: ORDER_LIST_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: ORDER_LIST_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const getUpdates = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_UPDATES_REQUEST
        });

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/updates/`);

        dispatch({
            type: GET_UPDATES_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: GET_UPDATES_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};




export const createQuotation = (quotation) => async (dispatch) => {
    try {
        dispatch({
            type: QUOTATION_CREATE_REQUEST
        });

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL_PROD}/api/brand/create/quotation/`, quotation);

        dispatch({
            type: QUOTATION_CREATE_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: QUOTATION_CREATE_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};



export const listQuotations = () => async (dispatch) => {
    try {
        dispatch({ type: QUOTATION_ALL_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/all/quotation/`);
        dispatch({
            type: QUOTATION_ALL_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: QUOTATION_ALL_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const getQuotation = (id) => async (dispatch) => {
    try {
        dispatch({ type: QUOTATION_DETAIL_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/quotation/${id}/`);
        dispatch({
            type: QUOTATION_DETAIL_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: QUOTATION_DETAIL_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};


export const duplicateQuotation = (id) => async (dispatch) => {
    try {
        dispatch({ type: DUPLICATE_QUOTATION_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/duplicate/quotation/${id}/`);
        dispatch({
            type: DUPLICATE_QUOTATION_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: DUPLICATE_QUOTATION_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const updateQuotation = (value) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_QUOTATION_REQUEST });
        
        const data = await axios.put(`${process.env.REACT_APP_API_URL_PROD}/api/brand/update/quotation/${value.uid}/`, value)

        dispatch({
            type: UPDATE_QUOTATION_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: UPDATE_QUOTATION_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const deleteQuotation = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_QUOTATION_REQUEST });
        await axios.delete(`${process.env.REACT_APP_API_URL_PROD}/api/brand/delete/quotation/${id}/`);
        dispatch({
            type: DELETE_QUOTATION_SUCCESS
        });
    } catch (error) {
        dispatch({
            type: DELETE_QUOTATION_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};



export const listClients = (bid) => async (dispatch) => {
    try {
        dispatch({ type: CLIENT_ALL_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/all/clients/${bid}/`);
        dispatch({
            type: CLIENT_ALL_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: CLIENT_ALL_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};

export const getClient = (id) => async (dispatch) => {
    try {
        dispatch({ type: CLIENT_DETAIL_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL_PROD}/api/brand/client/${id}/`);
        dispatch({
            type: CLIENT_DETAIL_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: CLIENT_DETAIL_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message
        });
    }
};
