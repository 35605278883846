import { lazy } from 'react';

// project import
import Loadable from '../Components/Loadable';
import MinimalLayout from '../Layout/MinimalLayout';

// render - login
const AuthLogin = Loadable(lazy(() => import('../pages/authentication/Login')));
const Main = Loadable(lazy(() => import('../pages/Home/Main')));
const Start = Loadable(lazy(() => import('../pages/Home/Start')));
const UserForm = Loadable(lazy(() => import('../pages/createbrand/UserForm')));
const AuthRegister = Loadable(lazy(() => import('../pages/authentication/Register')));
const EditUser = Loadable(lazy(() => import('../pages/authentication/EditUser')));
const ForgetPassword = Loadable(lazy(() => import('../pages/authentication/ForgetPassword')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/dashboard/home',
            element: <Start />
        },
        {
            path: '/createaccount',
            element: <UserForm />
        },
        {
            path: '/dashboard/login',
            element: <Main />
        },
        {
            path: '/dashboard/:cid/:bid/login',
            element: <AuthLogin />
        },
        {
            path: '/dashboard/:cid/:bid/register',
            element: <AuthRegister />
        },
        {
            path: '/dashboard/forgetpassword',
            element: <ForgetPassword />
        },
        {
            path: '/dashboard/password/reset/confirm/:uid/:token',
            element: <ResetPassword />
        },
        {
            path: '/dashboard/:cid/:bid/edituser/:uid',
            element: <EditUser />
        }
    ]
};

export default LoginRoutes;
