// assets
import { LoginOutlined } from '@ant-design/icons';
import { ImUserPlus } from "react-icons/im";
// icons
const icons = {
    LoginOutlined,
    ImUserPlus
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
    id: 'authentication',
    title: 'Authentication',
    type: 'group',
    children: [
        {
            id: 'register1',
            title: 'Create User',
            type: 'item',
            url: '/register',
            icon: icons.ImUserPlus
        }
    ]
};

export default pages;
