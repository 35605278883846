import { lazy } from 'react';

// project import
import Loadable from '../Components/Loadable';
import MainLayout from '../Layout/MainLayout';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('../pages/dashboard')));

// render - category
const CategoryDefault = Loadable(lazy(() => import('../pages/category')));
const AddCategory = Loadable(lazy(() => import('../pages/category/AddCategory')));
const EditCategory = Loadable(lazy(() => import('../pages/category/EditCategory')));
const EditCategoryVendor = Loadable(lazy(() => import('../pages/category/EditCategoryVendor')));
const AddNewCategory = Loadable(lazy(() => import('../pages/category/AddNewCategory')));
const CategoryDetail = Loadable(lazy(() => import('../pages/category/CategoryDetail')));
const AddUnit = Loadable(lazy(() => import('../pages/category/AddUnit')));
const UnpaidBills = Loadable(lazy(() => import('../pages/category/UnpaidBills')));
const UnPaidBillDetail = Loadable(lazy(() => import('../pages/category/UnPaidBillDetail')));
const GenerateReportStock = Loadable(lazy(() => import('../pages/category/GenerateReportStock')));
const GenerateReportVendor = Loadable(lazy(() => import('../pages/category/GenerateReportVendor')));

// render - cart
const CartDefault = Loadable(lazy(() => import('../pages/cart')));

// render - all orders
const OrderScreen = Loadable(lazy(() => import('../pages/allorders')));
const OrderDetail = Loadable(lazy(() => import('../pages/allorders/OrderDetail')));
const OrderDelivered = Loadable(lazy(() => import('../pages/allorders/OrderDelivered')));
const GenerateReport = Loadable(lazy(() => import('../pages/allorders/GenerateReport')));

// render - all packages
const Packages = Loadable(lazy(() => import('../pages/allpackages')));
const CustomPackage = Loadable(lazy(() => import('../pages/allpackages/CustomPackage')));
const PackageDetail = Loadable(lazy(() => import('../pages/allpackages/PackageDetail')));
const AddPackage = Loadable(lazy(() => import('../pages/allpackages/AddPackage')));
const EditPackage = Loadable(lazy(() => import('../pages/allpackages/EditPackage')));

// render - all Variation
const Variations = Loadable(lazy(() => import('../pages/allvariations')));
const VariationDetail = Loadable(lazy(() => import('../pages/allvariations/VariationDetail')));
const AddVariation = Loadable(lazy(() => import('../pages/allvariations/AddVariation')));
const EditVariation = Loadable(lazy(() => import('../pages/allvariations/EditVariation')));

// render - all package profits
const PackageProfits = Loadable(lazy(() => import('../pages/allprofits')));
const AddProfit = Loadable(lazy(() => import('../pages/allprofits/AddProfit')));
const EditProfit = Loadable(lazy(() => import('../pages/allprofits/EditProfit')));

// render - all users
const AllUsers = Loadable(lazy(() => import('../pages/allusers')));

// render - all clients
const AllClients = Loadable(lazy(() => import('../pages/allclients')));

// render - all quotation
const Quotation = Loadable(lazy(() => import('../pages/quotation')));
const Allquotations = Loadable(lazy(() => import('../pages/quotation/Allquotations')));
const EditQuotation = Loadable(lazy(() => import('../pages/quotation/EditQuotation')));
const QuotationDetail = Loadable(lazy(() => import('../pages/quotation/QuotationDetail')));

// render - Products
const Products = Loadable(lazy(() => import('../pages/products')));
const PackageVariation = Loadable(lazy(() => import('../pages/products/PackageVariation')));

// render - placeorder
const ShippingScreen = Loadable(lazy(() => import('../pages/placeorder/ShippingScreen')));
const PlaceOrder = Loadable(lazy(() => import('../pages/placeorder/PlaceOrder')));

// render - settings
const Settings = Loadable(lazy(() => import('../pages/settings/settings')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/dashboard',
    element: <MainLayout />,
    children: [
        {
            path: '/dashboard/:cid',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/:cid/:bid',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/:cid/:bid/allstocks',
            element: <CategoryDefault />
        },
        {
            path: '/dashboard/:cid/:bid/addstock/:uid',
            element: <AddCategory />
        },
        {
            path: '/dashboard/:cid/:bid/editstock/:uid',
            element: <EditCategory />
        },
        {
            path: '/dashboard/:cid/:bid/editstockvendor/:uid/:pid',
            element: <EditCategoryVendor />
        },
        {
            path: '/dashboard/:cid/:bid/category/:uid',
            element: <CategoryDetail />
        },
        {
            path: '/dashboard/:cid/:bid/addnewstock',
            element: <AddNewCategory />
        },
        {
            path: '/dashboard/:cid/:bid/addunit',
            element: <AddUnit />
        },
        {
            path: '/dashboard/:cid/:bid/stock/:uid/unpaidbill',
            element: <UnpaidBills />
        },
        {
            path: '/dashboard/:cid/:bid/stock/:uid/unpaidbill/:vid',
            element: <UnPaidBillDetail />
        },
        {
            path: '/dashboard/:cid/:bid/stockreport',
            element: <GenerateReportStock />
        },
        {
            path: '/dashboard/:cid/:bid/:uid/vendorreport',
            element: <GenerateReportVendor />
        },
        {
            path: '/dashboard/:cid/:bid/cart',
            element: <CartDefault />
        },
        {
            path: '/dashboard/:cid/:bid/shipping',
            element: <ShippingScreen />
        },
        {
            path: '/dashboard/:cid/:bid/placeorder',
            element: <PlaceOrder />
        },
        {
            path: '/dashboard/:cid/:bid/profits',
            element: <PackageProfits />
        },
        {
            path: '/dashboard/:cid/:bid/addnewprofit',
            element: <AddProfit />
        },
        {
            path: '/dashboard/:cid/:bid/editpackageprofit/:ppid',
            element: <EditProfit />
        },
        {
            path: '/dashboard/:cid/:bid/allpackages',
            element: <Packages />
        },
        {
            path: '/dashboard/:cid/:bid/custompackages',
            element: <CustomPackage />
        },
        {
            path: '/dashboard/:cid/:bid/package/:uid',
            element: <PackageDetail />
        },
        {
            path: '/dashboard/:cid/:bid/addpackage',
            element: <AddPackage />
        },
        {
            path: '/dashboard/:cid/:bid/editpackage/:uid',
            element: <EditPackage />
        },
        {
            path: '/dashboard/:cid/:bid/:pid/allvariations',
            element: <Variations />
        },
        {
            path: '/dashboard/:cid/:bid/:pid/variation/:uid',
            element: <VariationDetail />
        },
        {
            path: '/dashboard/:cid/:bid/:pid/addvariation',
            element: <AddVariation />
        },
        {
            path: '/dashboard/:cid/:bid/:pid/:wpid/editvariation/:uid',
            element: <EditVariation />
        },
        {
            path: '/dashboard/:cid/:bid/products',
            element: <Products />
        },
        {
            path: '/dashboard/:cid/:bid/package/:pid/variations',
            element: <PackageVariation />
        },
        {
            path: '/dashboard/:cid/:bid/allorders',
            element: <OrderScreen />
        },
        {
            path: '/dashboard/:cid/:bid/order/:uid',
            element: <OrderDetail />
        },
        {
            path: '/dashboard/:cid/:bid/orderdelivered',
            element: <OrderDelivered />
        },
        {
            path: '/dashboard/:cid/:bid/generatereport',
            element: <GenerateReport />
        },
        {
            path: '/dashboard/:cid/:bid/allusers',
            element: <AllUsers />
        },
        {
            path: '/dashboard/:cid/:bid/allquotations',
            element: <Allquotations />
        },
        {
            path: '/dashboard/:cid/:bid/createquotation',
            element: <Quotation />
        },
        {
            path: '/dashboard/:cid/:bid/:uid/editquotation',
            element: <EditQuotation />
        },
        {
            path: '/dashboard/:cid/:bid/:uid/quotation',
            element: <QuotationDetail />
        },
        {
            path: '/dashboard/:cid/:bid/allclients',
            element: <AllClients />
        },
        {
            path: '/dashboard/:cid/:bid/settings',
            element: <Settings />
        },
    ]
};

export default MainRoutes;
