import { lazy } from 'react';

// project import
import Loadable from '../Components/Loadable';

// render - dashboard
const Home = Loadable(lazy(() => import('../pages/Home')));

// ==============================|| MAIN ROUTING ||============================== //

const HomeRoutes = {
    path: '/:cid/home/dashboard',
    element: <Home />,
    children: []
};

export default HomeRoutes;
