import './App.scss';
import React, {useEffect}  from 'react'
import { checkAuthenticated, load_user } from './actions/actions'
// import { BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import {connect} from 'react-redux'
import { ToastContainer } from 'react-toastify';

import Routes from './routes';
import ThemeCustomization from './themes';
import ScrollTop from './Components/ScrollTop';
import './toast.css';
// import 'react-toastify/dist/ReactToastify.css';


function App({checkAuthenticated, load_user}) {
  useEffect(() => {
    checkAuthenticated();
    load_user();
  }, [checkAuthenticated, load_user])
  return (
      <ThemeCustomization>
        <ScrollTop>
            <Routes />
        </ScrollTop>
        <ToastContainer />
    </ThemeCustomization>
  );
}

export default connect(null, { checkAuthenticated, load_user })(App);
